import { Types } from '@riskforge/jawa-core';
import { colors, layout, text } from '@riskforge/jawa-theme';
import { DateTime } from 'luxon';
import { isDate, isEmpty } from 'lodash';

import {
  Icons,
  Link,
  React,
  styled,
  useTranslation,
  GlobalContext,
  useAuth0,
} from '@riskforge/platform-web';
import { GlobalContext as JawaContext } from 'contexts';
import { Signature, ErrorComponent, Spinner } from 'components';
import { brandConfig, isMockData } from 'utils/brand-config';
import { useAuth0 as useMockAuth0 } from 'utils/mock-auth0';

interface ReportListProps {
  className?: string;
  reports: Types.Reports;
}

const ReportList: React.FunctionComponent<ReportListProps> = ({ className, reports }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [exportError, setExportError] = React.useState<Error | undefined>(undefined);
  const [exportFetchingId, setExportFetchingId] = React.useState<string | undefined>(undefined);

  const { actionCreator } = React.useContext(GlobalContext) as JawaContext;
  const auth0Context = isMockData ? useMockAuth0 : useAuth0;
  const { getTokenSilently } = auth0Context();

  // const isAnyReportComplete = !isEmpty(filter(reports, (r) => !!r?.signature));

  const handleDownloadExport = async (reportId: string): Promise<void> => {
    if (isMockData) return;
    setExportFetchingId(reportId);
    try {
      const token = await getTokenSilently();
      if (!token || !brandConfig.apiHost || isEmpty(brandConfig.apiHost)) {
        throw new Error('EXPORT ERROR: Missing token');
      }
      await actionCreator.downloadExport(token, brandConfig.apiHost, reportId).catch(setExportError);
    } catch (error) {
      setExportError(error);
      setExportFetchingId(undefined);
    }
    setExportFetchingId(undefined);
  };

  return (
    <>
      <StyledReportList className={className}>
        {Object.values(reports).map((report: Types.KeyedReport) => {
          let downloadColumnElement = <EmptyDiv />;
          if (!exportFetchingId) {
            downloadColumnElement = (
              <DownloadLink onClick={(): Promise<void> => handleDownloadExport(report.id)}>
                <Icons.fa.FaArrowAltCircleDown />
              </DownloadLink>
            );
          }
          if (exportFetchingId === report.id) {
            downloadColumnElement = <Spinner isSmall />;
          }

          return (
            <StyledReportItem key={`r${report.id}`} hasSecondColumn>
              <ReportItem report={report} />
              {downloadColumnElement}
            </StyledReportItem>
          );
        })}
      </StyledReportList>
      {exportError && <ErrorComponent error={exportError} />}
    </>
  );
};

const ReportItem: React.FunctionComponent<ReportItemProps> = ({
  className,
  report,
}) => {
  const { t } = useTranslation();
  const reportSigned = (): boolean => report.signature !== undefined;
  const reportDueDeltaDays = (): number => (new Date().getTime() - report.due.getTime()) / 1000 / 60 / 1440;
  const reportOverdue = (): boolean => !reportSigned() && reportDueDeltaDays() > 0;
  const reportDueDelta = (): string => t(reportOverdue() ? 'daysLate' : 'days', { days: Math.round(Math.abs(reportDueDeltaDays())) });
  const reportStatusLabel = (): string => (reportOverdue() ? t('overdue') : t('dueIn'));
  const reportPendingStatus = (): React.ReactNode => (
    <>
      <p>{reportStatusLabel()}</p>
      <p>{reportDueDelta()}</p>
    </>
  );
  const reportStatus = (): React.ReactNode => (report.signature ? <Signature signature={report.signature} /> : reportPendingStatus());
  const reportOutstandingIcon = (): React.ReactNode => (reportOverdue() ? <Icons.fa.FaExclamationCircle /> : <Icons.fa.FaChevronRight />);
  const reportIcon = (): React.ReactNode => (report.signature ? <Icons.fa.FaCheck /> : reportOutstandingIcon());
  const completeBefore = (due: Date): React.ReactNode => (
    <>
      <p>{t('completeBefore')}</p>
      <p>{t('formattedDate', { date: due })}</p>
    </>
  );
  const digitallySigned = (signature: Types.Signature): React.ReactNode => {
    const dateString = isDate(signature.timestamp) ? signature.timestamp.toUTCString() : signature.timestamp;
    const signedDateTime = DateTime.fromISO(dateString);
    return (
      <>
        <p>{t('digitallySigned')}</p>
        <p>{signedDateTime.isValid && signedDateTime.toFormat('ff')}</p>
      </>
    );
  };


  const reportNextStep = (): React.ReactNode => (report.signature ? digitallySigned(report.signature) : completeBefore(report.due));
  return (
    <Link to={`/report/${encodeURIComponent(report.id)}`}>
      <StyledReportBox className={className} overdue={reportOverdue()} signed={reportSigned()}>
        <ReportLabel>
          <span>{report.label}</span>
          &nbsp;
          <span>{t('report')}</span>
        </ReportLabel>
        <ReportMetric>
          {reportNextStep()}
        </ReportMetric>
        <ReportMetric>
          {reportStatus()}
        </ReportMetric>
        {reportIcon()}
      </StyledReportBox>
    </Link>
  );
};

interface ReportItemProps {
  className?: string;
  report: Types.KeyedReport;
}

const StyledReportList = styled.ul`
  overflow-y: scroll;
  width: 100%;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
`;

const StyledReportItem = styled.li <{ hasSecondColumn: boolean }>`
  display: grid;
  grid-template-columns: ${(p): string => (p.hasSecondColumn ? '1fr auto' : 'auto')};
  grid-gap: 0.5rem;
  align-items: center;
`;

const ReportLabel = styled.div`
  span {
    font-weight: ${text.reportList.labelWeight};
    font-size: ${text.reportList.labelSize};
  }
`;

const ReportMetric = styled.div`
  * {
    font-size: ${text.reportList.metricSize};
    line-height: ${text.reportList.metricLine};
  }
  > :first-child {
    font-weight: ${text.reportList.metricLabelWeight};
  }
`;

interface StyledReportBoxProps {
  overdue: boolean;
  signed: boolean;
}

const StyledReportBox = styled.div<StyledReportBoxProps>`
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
  &:hover {
    box-shadow: 5px 9px 10px 0px rgba(0,0,0,0.25);
    transform: translate(-0.1rem, -0.1rem);
    opacity: 1;
  }
  width: 95%;
  height: 5rem;
  background-color: ${brandConfig.widgetBackground};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${layout.widgetMargin};
  padding: ${layout.widgetPadding};
  border-radius: ${layout.widgetBorderRadius};
  cursor: pointer;
  opacity: ${({ signed }: { signed: boolean }): string => (signed ? '40%' : '80%')};
  > * {
    flex: 1 1 1rem;
    max-width: 33%;
  }
  > *:first-child span:last-child {
    @media (max-width: 720px) {
      display: none;
    }
  }
  > *:nth-child(2) {
    @media (max-width: 720px) {
      display: none;
    }
  }
  > *:nth-last-child(2) {
    @media (max-width: 720px) {
      max-width: 50%;
    }
    * {
      color: ${({ overdue }: { overdue: boolean }): string => (overdue ? colors.warn : colors.text)} !important;
    }
  }
  > *:last-child {
    flex: 0 0 ${layout.reportList.reportStatusIconSize};
    font-size: ${layout.reportList.reportStatusIconSize};
    @media (max-width: 720px) {
      font-size: calc(${layout.reportList.reportStatusIconSize} * 0.75);
    }
    fill: ${({ signed, overdue }: { signed: boolean; overdue: boolean }): string => (signed ? colors.ok : (overdue ? colors.warn : colors.text))};
  }
`;

const DownloadLink = styled.div`
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  svg {
    font-size: 3rem;
  }
`;
const EmptyDiv = styled.div`
  width: 3rem;
`;

export default ReportList;
