import {
  React, styled, keyframes,
} from '@riskforge/platform-web';
import { colors } from '@riskforge/jawa-theme';

interface SpinnerProps {
  isMini?: boolean;
  isSmall?: boolean;
}

const load8 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const SpinnerElement = styled.div`
  font-size: 100px;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin: 0 auto;
  top: 0.6em;
  font-size: 100px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.1em solid rgba(220, 220, 220, 0.3);
  border-right: 0.1em solid rgba(220, 220, 220, 0.3);
  border-bottom: 0.1em solid rgba(220, 220, 220, 0.3);
  border-left: 0.1em solid rgba(220, 220, 220, 0.8);
  transform: translateZ(0);
  animation: ${load8} 1.1s infinite linear;
  &:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }
`;
const MiniSpinnerElement = styled(SpinnerElement)`
  font-size: 20px;
  top: 0;
  border-left: 0.1em solid ${colors.text};
`;
const SmallSpinnerElement = styled(SpinnerElement)`
  font-size: 48px;
  top: 0;
`;
const SpinnerWrapper = styled.div`
  height: 15em;
`;

const Spinner: React.FC<SpinnerProps> = ({ isMini, isSmall }: SpinnerProps) => {
  if (isMini) return <MiniSpinnerElement />;
  if (isSmall) return <SmallSpinnerElement />;
  return (
    <SpinnerWrapper>
      <SpinnerElement />
    </SpinnerWrapper>
  );
};

export default Spinner;
