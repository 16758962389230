import React from 'react';
import { brandConfig } from 'utils/brand-config';
import {
  DemoLogo,
  Demo1Logo,
  Demo2Logo,
  Demo3Logo,
  Demo4Logo,
  Demo5Logo,
  TriveLogo,
  KainosLogo,
  NewStateLogo,
  TailwaterLogo,
  TrinityHuntLogo,
  StonePointLogo,
  AbryLogo,
  AltamontLogo,
  KelsoLogo,
  AconLogo,
} from 'components';

interface BrandLogoProps {
  color?: string;
}

const BrandLogo: React.FC<BrandLogoProps> = ({ color }) => {
  switch (brandConfig.brandId) {
    case 'DEMO':
      return <DemoLogo color={color} />;

    case 'DEMO1':
      return <Demo1Logo color={color} />;

    case 'DEMO2':
      return <Demo2Logo color={color} />;

    case 'DEMO3':
      return <Demo3Logo color={color} />;

    case 'DEMO4':
      return <Demo4Logo color={color} />;

    case 'DEMO5':
      return <Demo5Logo color={color} />;

    case 'TRIVE':
      return <TriveLogo color={color} />;

    case 'KAINOS':
      return <KainosLogo color={color} />;

    case 'NEWSTATE':
      return <NewStateLogo color={color} />;

    case 'TAILWATER':
      return <TailwaterLogo color={color} />;

    case 'TRINITYHUNT':
      return <TrinityHuntLogo color={color} />;

    case 'STONEPOINT':
      return <StonePointLogo color={color} />;

    case 'ABRY':
      return <AbryLogo color={color} />;

    case 'ALTAMONT':
      return <AltamontLogo color={color} />;

    case 'KELSO':
      return <KelsoLogo color={color} />;

    case 'ACON':
      return <AconLogo color={color} />;

    default:
      return <DemoLogo color={color} />;
  }
};

export default BrandLogo;
