import { colors } from '@riskforge/jawa-theme';
import {
  React,
  styled,
} from '@riskforge/platform-web';
import KainosLogoImg from './kainos.svg';

interface KainosLogoProps {
  color?: string;
}

interface FormStyleProps {
  logoColor?: string;
}

const LogoWrapper = styled.div<FormStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 4rem;
  width: 15rem;
`;
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  filter: brightness(0) invert(1);
`;

const KainosLogo: React.FC<KainosLogoProps> = ({ color }: KainosLogoProps) => {
  const logoColor = color || colors.text;
  return (
    <LogoWrapper logoColor={logoColor}>
      <StyledLogo src={KainosLogoImg} alt="Kainos Capital" />
    </LogoWrapper>
  );
};

export default KainosLogo;
