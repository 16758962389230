/* eslint-disable @typescript-eslint/no-unused-vars */
export interface BrandConfigProps {
  brandId: string;
  brandName: string;
  partnerId?: string;
  colorPrimary: string;
  colorDark: string;
  widgetBackground: string;
  brandLogoPath?: string;
  partnerLogoPath?: string;
  apiHost: string;
}

const LOCAL_CONFIG = {
  brandId: 'AXYQ',
  brandName: 'AXYQ Equity',
  partnerId: undefined,
  colorPrimary: '#536379',
  colorDark: '#204679',
  widgetBackground: '#6f85a2',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'http://localhost:3003',
};

const STAGING_CONFIG = {
  brandId: 'AXYQ',
  brandName: 'AXYQ Equity',
  partnerId: undefined,
  colorPrimary: '#536379',
  colorDark: '#204679',
  widgetBackground: '#6f85a2',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'http://stage.rfly.comply.cloud:3000',
};

const MOCK_CONFIG = {
  brandId: 'MOCK',
  brandName: 'AXYQ Equity',
  partnerId: undefined,
  colorPrimary: '#536379',
  colorDark: '#204679',
  widgetBackground: '#6f85a2',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: '',
};

export const LOCALHOST_BACKEND_CONFIG = {
  brandId: 'LOCAL',
  brandName: 'Local Capital',
  colorPrimary: '#536379',
  colorDark: '#204679',
  widgetBackground: '#6f85a2',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'http://localhost:3003',
};

const DEMO1_CONFIG = {
  brandId: 'DEMO1',
  brandName: 'NGP Energy Capital',
  colorPrimary: '#39586F',
  colorDark: '#16222b',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/Demo1Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo1-portco.comply.cloud/demo1',
};

const DEMO2_CONFIG = {
  brandId: 'DEMO2',
  brandName: 'CXYQ Capital',
  colorPrimary: '#39586F',
  colorDark: '#16222b',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/Demo2Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo2-portco.comply.cloud/demo2',
};

const DEMO3_CONFIG = {
  brandId: 'DEMO3',
  brandName: 'DXYQ Capital',
  colorPrimary: '#39586F',
  colorDark: '#16222b',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/Demo3Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo3-portco.comply.cloud/demo3',
};

const DEMO4_CONFIG = {
  brandId: 'DEMO4',
  brandName: 'EXYQ Capital',
  colorPrimary: '#39586F',
  colorDark: '#16222b',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/Demo4Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo4-portco.comply.cloud/demo4',
};

const DEMO5_CONFIG = {
  brandId: 'DEMO5',
  brandName: 'FXYQ Capital',
  colorPrimary: '#39586F',
  colorDark: '#16222b',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/Demo5Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo5-portco.comply.cloud/demo5',
};

const TRIVE_CONFIG = {
  brandId: 'TRIVE',
  brandName: 'Trive Capital',
  colorPrimary: '#39586F',
  colorDark: '#16222b',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/TriveLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://trive.comply.cloud/trive',
};

const HIG_CONFIG = {
  brandId: 'HIG',
  brandName: 'HIG Capital',
  colorPrimary: '#39586F',
  colorDark: '#16222b',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://hig-portco.comply.cloud/trinityhunt',
};

const KAINOS_CONFIG = {
  brandId: 'KAINOS',
  brandName: 'Kainos Capital',
  colorPrimary: '#39586F',
  colorDark: '#16222b',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/KainosLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://kainos-portco.comply.cloud',
};

const NEWSTATE_CONFIG = {
  brandId: 'NEWSTATE',
  brandName: 'New State Capital Partners',
  colorPrimary: '#003764',
  colorDark: '#003764',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/NewStateLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://newstate-portco.comply.cloud/newstate',
};

const TAILWATER_CONFIG = {
  brandId: 'TAILWATER',
  brandName: 'Tailwater Capital',
  colorPrimary: '#79abd2',
  colorDark: '#0b3f69',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/TailwaterLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://tailwater-portco.comply.cloud/tailwater',
};

const TRINITYHUNT_CONFIG = {
  brandId: 'TRINITYHUNT',
  brandName: 'Stone Point Capital',
  colorPrimary: '#36617f',
  colorDark: '#013c4d',
  widgetBackground: '#4b7391',
  brandLogoPath: 'components/TrinityHuntLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://trinityhunt-portco.comply.cloud/trinityhunt',
};

const STONEPOINT_CONFIG = {
  brandId: 'STONEPOINT',
  brandName: 'Stone Point Capital',
  colorPrimary: '#536379',
  colorDark: '#204679',
  widgetBackground: '#6f85a2',
  brandLogoPath: 'components/StonePointLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://stonepoint-portco.comply.cloud/stonepoint',
};

const ABRY_CONFIG = {
  brandId: 'ABRY',
  brandName: 'Abry Partners, LLC',
  colorPrimary: '#536379',
  colorDark: '#204679',
  widgetBackground: '#6f85a2',
  brandLogoPath: 'components/AbryLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://abry-portco.comply.cloud/abry',
};

const ALTAMONT_CONFIG = {
  brandId: 'ALTAMONT',
  brandName: 'Altamont Capital Partners',
  colorPrimary: '#536379',
  colorDark: '#204679',
  widgetBackground: '#6f85a2',
  brandLogoPath: 'components/AltamontLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://altamont-portco.comply.cloud/altamont',
};

const KELSO_CONFIG = {
  brandId: 'KELSO',
  brandName: 'Kelso Private Equity',
  colorPrimary: '#536379',
  colorDark: '#204679',
  widgetBackground: '#6f85a2',
  brandLogoPath: 'components/KelsoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://kelso-portco.comply.cloud/kelso',
};

const ACON_CONFIG = {
  brandId: 'ACON',
  brandName: 'Acon Capital',
  colorPrimary: '#536379',
  colorDark: '#204679',
  widgetBackground: '#6f85a2',
  brandLogoPath: 'components/WhiteLabelLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://acon-portco.comply.cloud/acon',
};

const DEFAULT_CONFIG = TRIVE_CONFIG;
// const LOCALHOST_TEST = HIG_CONFIG;
const LOCALHOST_TEST = LOCALHOST_BACKEND_CONFIG;

const getBrandConfig = (): BrandConfigProps => {
  const url = window?.location?.origin;
  if (!url) return DEFAULT_CONFIG;
  if (url.includes('report-demo.comply.cloud')) {
    return MOCK_CONFIG;
  }
  if (url.includes('report.comply.cloud')) {
    return MOCK_CONFIG;
  }
  if (url.includes('localhost')) {
    return LOCALHOST_TEST;
  }
  if (url.includes('demo1-portco.comply.cloud')) {
    return DEMO1_CONFIG;
  }
  if (url.includes('demo2-portco.comply.cloud')) {
    return DEMO2_CONFIG;
  }
  if (url.includes('demo3-portco.comply.cloud')) {
    return DEMO3_CONFIG;
  }
  if (url.includes('demo4-portco.comply.cloud')) {
    return DEMO4_CONFIG;
  }
  if (url.includes('demo5-portco.comply.cloud')) {
    return DEMO5_CONFIG;
  }
  if (url.includes('report.trivecapital.com')) {
    return TRIVE_CONFIG;
  }
  if (url.includes('kainos-portco.comply.cloud')) {
    return KAINOS_CONFIG;
  }
  if (url.includes('newstate')) {
    return NEWSTATE_CONFIG;
  }
  if (url.includes('tailwater')) {
    return TAILWATER_CONFIG;
  }
  if (url.includes('trinityhunt')) {
    return TRINITYHUNT_CONFIG;
  }
  if (url.includes('hig')) {
    return HIG_CONFIG;
  }
  if (url.includes('stonepoint')) {
    return STONEPOINT_CONFIG;
  }
  if (url.includes('abry')) {
    return ABRY_CONFIG;
  }
  if (url.includes('altamont')) {
    return ALTAMONT_CONFIG;
  }
  if (url.includes('kelso')) {
    return KELSO_CONFIG;
  }
  if (url.includes('acon-portco.comply.cloud')) {
    return ACON_CONFIG;
  }

  return DEFAULT_CONFIG;
};

export const brandConfig = getBrandConfig();

export const isMockData = ['MOCK'].includes(brandConfig?.brandId);

export default getBrandConfig;
