import { Types } from '@riskforge/jawa-core';
import { colors } from '@riskforge/jawa-theme';
import {
  Container,
  Icons,
  React,
  styled,
} from '@riskforge/platform-web';
import { SettingsModal, BrandLogo, HelpModal } from 'components';
import { brandConfig } from 'utils/brand-config';

const userProfile = (user: Types.User | undefined) => (
  user ? { fullName: user.fullName, email: user.email } : undefined
);

const StyledContainer: React.FunctionComponent<StyledContainerProps> = ({
  children, fullWidth, user,
}) => {
  const [isSettingsOpen, setIsSettingsOpen] = React.useState<boolean>(false);
  const [isHelpOpen, setIsHelpOpen] = React.useState<boolean>(false);

  const trayItems = [
    {
      icon: Icons.fa.FaQuestionCircle,
      handleClick: (): void => setIsHelpOpen(!isHelpOpen),
    },
    {
      icon: Icons.fa.FaCog,
      handleClick: (): void => setIsSettingsOpen(!isSettingsOpen),
    },
  ];

  if (fullWidth) {
    return (
      <StyledFullWidthInnerContainer
        brand={BrandLogo}
        trayItems={trayItems}
        userProfile={userProfile(user)}
        handleAvatarClick={(): void => setIsSettingsOpen(!isSettingsOpen)}
      >
        {children}
        <HelpModal
          isOpen={isHelpOpen}
          onClose={(): void => setIsHelpOpen(false)}
        />
        <SettingsModal
          isOpen={isSettingsOpen}
          onClose={(): void => setIsSettingsOpen(false)}
        />
      </StyledFullWidthInnerContainer>
    );
  }

  return (
    <StyledInnerContainer
      brand={BrandLogo}
      trayItems={trayItems}
      userProfile={userProfile(user)}
      handleAvatarClick={(): void => setIsSettingsOpen(!isSettingsOpen)}
    >
      <WidthConstraint>{children}</WidthConstraint>
      <HelpModal
          isOpen={isHelpOpen}
          onClose={(): void => setIsHelpOpen(false)}
        />
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={(): void => setIsSettingsOpen(false)}
      />
    </StyledInnerContainer>
  );
};

interface StyledContainerProps {
  user?: Types.User;
  fullWidth?: boolean;
}

const StyledInnerContainer = styled(Container)`
  background-color: ${brandConfig.colorPrimary};
  color: ${colors.text};
  width: 100%;
  height: 100%;

  & > * {
    overflow: hidden auto;
    flex: 1 1 auto;
  }
  & > *:first-child {
    flex: 0 0 auto;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    height: 4rem;
    display: grid;
    grid-template-columns: 1fr 18rem 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    & > *:first-child {
      order: 2;
      justify-content: center;
      width: 18rem;
    }
    & > *:nth-child(2) {
      order: 1;
    }
    & > *:last-child {
      order: 3;
    }
    @media (max-width: 720px) {
      grid-template-columns: 1fr 4.75fr 1fr;
    }
  }
  h1 {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 600;
    @media (max-width: 1000px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledFullWidthInnerContainer = styled(StyledInnerContainer)`
  & > *:first-child {
    grid-template-columns: 0 18rem 1fr;
    border-bottom: none;
    & > * {
      border-bottom: 1px solid rgba(0,0,0,0.25);
    }
    & > *:first-child {
      border-bottom: none;
      background: ${colors.darkerLeftNav};
    }
  }
`;

const WidthConstraint = styled.div`
  width: calc(100% - 4rem);
  max-width: 60rem;
  margin: 0 auto;
  padding: 2rem;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3rem;
  > * {
    flex: 0 1 auto;
  }
`;

export default StyledContainer;
