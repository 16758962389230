import React, { useContext } from 'react';
import { mockAuth0User, Auth0User } from 'mockData/mock-user';

export interface Auth0RedirectState {
  targetUrl?: string;
}

export interface Auth0Context {
  user?: Auth0User;
  getTokenSilently(): string;
  logout(): void;
}
interface Auth0ProviderOptions {
  children: React.ReactElement;
}

export const auth0Context = React.createContext<Auth0Context | undefined>(undefined);
export const useAuth0 = () => useContext(auth0Context)!;
export const MockAuth0Provider = ({
  children,
}: Auth0ProviderOptions) => {
  const user: Auth0User = mockAuth0User;

  return (
    <auth0Context.Provider
      value={{
        user,
        logout: (): void => {},
        getTokenSilently: (): string => 'fake-token',
      }}
    >
      {children}
    </auth0Context.Provider>
  );
};

export const MockAuthConfig = {
  domain: 'auth.riskforge.io',
  clientId: 'o8anuZUaGslIXE9lQj1TE77A1qsLcfuD',
  audience: 'baby-yoda.comply.cloud',
};
