import { colors } from '@riskforge/jawa-theme';
import {
  React,
  styled,
} from '@riskforge/platform-web';
import Demo5LogoImg from './fxyq.png';

interface Demo5LogoProps {
  color?: string;
}

interface FormStyleProps {
  logoColor?: string;
}

const LogoWrapper = styled.div<FormStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 6rem;
`;
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Demo5Logo: React.FC<Demo5LogoProps> = ({ color }: Demo5LogoProps) => {
  const logoColor = color || colors.text;
  return (
    <LogoWrapper logoColor={logoColor}>
      <StyledLogo src={Demo5LogoImg} alt="FXYQ Capital" />
    </LogoWrapper>
  );
};

export default Demo5Logo;
