import { Types } from '@riskforge/jawa-core';
// import mockReportResponse from './mockReportResponse.json';

const mockReportDetail = {
  id: '5efb3758c27445a57983a2c2',
  name: '2020 Q2',
  template: { id: '5ef2626c6e90989eb4cffd35', name: 'Technology Company Template' },
  company: { id: '5edf2b326e90982811cfe487', name: 'Company Name' },
  dueBy: '2020-07-15T13:00:08.665Z',
  status: 'NON_COMPLIANT',
  questionStatistics: { COMPLIANT: 93, NON_COMPLIANT: 3, NOT_APPLICABLE: 0 },
  instructions: 'This matrix details the approvals required for key operational actions and decisions made by the CEO and direct and indirect reports which are either at a level of materiality and/or importance as to require approval of a member of the senior management team and/or ABC Capital. This matrix may be subject to revision by ABC Capital.\n\n\nApproval is required as indicated in the matrix. If there are to be further approvals or notifications required with respect to certain actions / decisions the person who you have sought approval from will discuss with you further. Approval requests and responses should be given in writing. Functions, hubs, and branches should develop their own sub-group authority matrices in line with the format of this matrix to ensure that actions and decisions outside of those requiring approval in this matrix are appropriately approved.\n\n\nThis matrix will be distributed at the close of each fiscal quarter for senior management attestation (senior management defined below). Please attest "A" to indicate compliance with each policy and procedure during the preceding quarter; please note "NC" to indicate an event of non-compliance during the preceding quarter. If you need to provide further explanation, you can do so in the text box option for each question.\n\n\nThe senior management is defined as: Chief Executive Officer, Chief Operations Officer, Controller or Chief Financial Officer.\n\n\nABC Capital Approval: Robin Wave and Dan Jones',
  sections: {
    '5ef26c9c6e9098646bcffe00': {
      id: '5ef26c9c6e9098646bcffe00',
      type: 'AUTHORITY_MATRIX',
      name: 'General Business Activity',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e90982b6dcffe01': {
          id: '5ef26c9c6e90982b6dcffe01',
          columns: ['A. Any change in strategic direction of the Company', 'None', 'CEO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098c19fcffe02': {
          id: '5ef26c9c6e9098c19fcffe02',
          columns: ['B. Entry into any joint venture', 'None', 'CEO', 'Deal Lead\nLegal Review'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90982794cffe03': {
          id: '5ef26c9c6e90982794cffe03',
          columns: ['C. Purchase or establishment of any new subsidiaries or affiliates of the Company', 'None', 'CEO', 'Deal Lead\nLegal Review'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098bc54cffe04': {
          id: '5ef26c9c6e9098bc54cffe04',
          columns: ['D. Issuance or sale of any securities of the Company', 'None', 'CEO', 'Deal Lead\nLegal Review'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90988e98cffe05': {
          id: '5ef26c9c6e90988e98cffe05',
          columns: ['E. Entry into any discussions regarding the sale of the company or the acquisition of another company', 'None', 'CEO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098407fcffe06': {
          id: '5ef26c9c6e9098407fcffe06',
          columns: ['F. Sensitive business, operating, or financial information given to third parties', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098c82acffe07': {
          id: '5ef26c9c6e9098c82acffe07',
          columns: ['G. Engagement of any legal advisors not previously authorized', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909846f6cffe08': {
          id: '5ef26c9c6e909846f6cffe08',
          columns: ['H. Engagement of any operational consultant', 'None', 'CEO, CFO\n', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909843a3cffe09': {
          id: '5ef26c9c6e909843a3cffe09',
          columns: ['I. Engagement of any financial advisors (audit, tax, appraisal, etc.)', 'None', 'CEO, CFO\n', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90981b21cffe0a': {
          id: '5ef26c9c6e90981b21cffe0a',
          columns: ['J. Adopt, acquire, change, test, materially repair, release or terminate any IT/IS strategy, policy, system or IT/IS network or licenses', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 10,
        totalQuestions: 10,
      },
      questionStatistics: { COMPLIANT: 10, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 10, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 10, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 10, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e9098cd03cffe0e': {
      id: '5ef26c9c6e9098cd03cffe0e',
      type: 'AUTHORITY_MATRIX',
      name: 'Customers / Marketing',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e9098760ccffe0f': {
          id: '5ef26c9c6e9098760ccffe0f',
          columns: ['A. Entering into or revising downward (significantly) a contract\'s margin (outside the general range)', 'Sales associated with contract / customer greater than $1 million', 'CEO, CFO', 'Immediate notification of Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098f689cffe10': {
          id: '5ef26c9c6e9098f689cffe10',
          columns: ['B. Terminates relationship with any customer, or customer terminates relationship with the company', 'None', 'CEO, CFO', 'Immediate notification of Deal Lead via conversation/team meeting'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90988655cffe11': {
          id: '5ef26c9c6e90988655cffe11',
          columns: ['C. Extension of customer payment terms beyond standard terms, conversion of account receivable to note receivable, or write-down of customer account; Contract Value $200 thousand', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90985f4acffe12': {
          id: '5ef26c9c6e90985f4acffe12',
          columns: ['D. Material change to any customer rebate program', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098a911cffe13': {
          id: '5ef26c9c6e9098a911cffe13',
          columns: ['E. Material communications with customers (e.g. safety incidents, significant project updates)', 'None', 'CEO, CFO', 'Deal Lead\nLegal Review'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 5,
        totalQuestions: 5,
      },
      questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e909863f3cffe16': {
      id: '5ef26c9c6e909863f3cffe16',
      type: 'AUTHORITY_MATRIX',
      name: 'Suppliers / Pricing',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e909884a2cffe17': {
          id: '5ef26c9c6e909884a2cffe17',
          columns: ['A. Any significant change to or termination of material terms with any supplier', 'Costs associated with contract / supplier greater than $100 thousand annually', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909872b3cffe18': {
          id: '5ef26c9c6e909872b3cffe18',
          columns: ['B. Any supplier contract, which involves any security being granted including a charge, pledge, or equivalent or any parent guarantee.', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5fdbc16f00ad7e550a72865c': {
          id: '5fdbc16f00ad7e550a72865c',
          columns: ['C. Blanket purchase order value', 'Greater than $75 thousand', 'CEO, CFO', 'Immediate notification of Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 3,
        totalQuestions: 3,
      },
      questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e90980e2ccffe19': {
      id: '5ef26c9c6e90980e2ccffe19',
      type: 'AUTHORITY_MATRIX',
      name: 'Finance - Operating',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e90983deacffe1a': {
          id: '5ef26c9c6e90983deacffe1a',
          columns: ['A. Travel, entertainment, corporate credit cards and petty cash expenses - Quarterly Review of Regional Heads', 'None', 'CEO & CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098293fcffe1b': {
          id: '5ef26c9c6e9098293fcffe1b',
          columns: ['B. Senior management team member expense reimbursement', 'None', 'CEO', 'CEO will notify Deal Lead of any expenses outside of standard spending patterns'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909869e9cffe1c': {
          id: '5ef26c9c6e909869e9cffe1c',
          columns: ['C. Non-employee family member or significant other travel: All costs of non-employee family member / significant other are to be borne by the employee', 'None', 'CEO', 'Deal Lead Review'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909887d2cffe1d': {
          id: '5ef26c9c6e909887d2cffe1d',
          columns: ['D. Fixed asset impairment (outside normal depreciation policy)', 'None', 'CEO, CFO', 'Immediate notification of Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90981faecffe1e': {
          id: '5ef26c9c6e90981faecffe1e',
          columns: ['E. Inventory items impairment', 'None', 'CEO, CFO', 'Immediate notification of Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },

        '5fdbc16f00ad7e1691728663': {
          id: '5fdbc16f00ad7e1691728663',
          columns: ['F. Inventory items liquidation mark-downs', 'Applies to greater than $25 thousand of inventory value', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5fdbc16f00ad7ef58f728664': {
          id: '5fdbc16f00ad7ef58f728664',
          columns: ['G. Revaluation of any assets', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 7,
        totalQuestions: 7,
      },
      questionStatistics: { COMPLIANT: 7, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 7, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 7, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 7, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e9098c927cffe1f': {
      id: '5ef26c9c6e9098c927cffe1f',
      type: 'AUTHORITY_MATRIX',
      name: 'Finance - Investments',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e9098d7dccffe20': {
          id: '5ef26c9c6e9098d7dccffe20',
          columns: ['A. Growth Capital Expenditure (includes acquisition of assets through operating or finance leasing and entire costs of capital projects including lease commitments, working capital requirements and non-capitalized costs). Further approval required in the event of any material change to previously approved Capital Expenditure or if actual expenditure exceeds or is expected to exceed more than 150% of the previously approved Capital Expenditure) above', '$150 thousand', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5fdbc16f00ad7e1727728667': {
          id: '5fdbc16f00ad7e1727728667',
          columns: ['B. Any repair, maintenance, or growth Capital Expenditure above $1 thousand requires preparation of a Formal Request. Any Capital Expenditure above $10 thousand requires preparation of a Formal Request and approval by Fund.', '$1 thousand / $10 thousand', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'NON_COMPLIANT' as Types.AttestationOption,
            explanation: 'Did not contact Deal Lead with disposal of 1 fixed asset valued at $115,000',
            documents: {
              'b5919106-d2cb-47b0-9431-48ca836f5bb9': {
                id: 'b5919106-d2cb-47b0-9431-48ca836f5bb9',
                name: 'Receipts_of_Disposal_of_Asset.pdf',
                type: 'PDF',
              },
            },
          },
        },
        '5ef26c9c6e9098e0bdcffe21': {
          id: '5ef26c9c6e9098e0bdcffe21',
          columns: ['C. Dispose, scrap or transfer of fixed assets (applies to the higher of the anticipated disposal proceeds of the asset and the net book value of the asset) above', '$7.5 thousand', 'CEO, CFO', 'Fund'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'NON_COMPLIANT' as Types.AttestationOption,
            explanation: 'Did not contact Deal Lead with disposal of 1 fixed asset valued at $115,000',
            documents: {
              'b5919106-d2cb-47b0-9431-48ca836f5bb9': {
                id: 'b5919106-d2cb-47b0-9431-48ca836f5bb9',
                name: 'Receipts_of_Disposal_of_Asset.pdf',
                type: 'PDF',
              },
            },
          },
        },
        '5ef26c9c6e90980277cffe22': {
          id: '5ef26c9c6e90980277cffe22',
          columns: ['D. Business acquisition (further approval required in the event of any material change to a previously approved acquisition)', 'None', 'CEO, CFO', 'Fund'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'NON_COMPLIANT' as Types.AttestationOption,
            explanation: 'COO not involved in business acquisition due to sabbatical leave',
            documents: {},
          },
        },
        '5ef26c9c6e9098d502cffe23': {
          id: '5ef26c9c6e9098d502cffe23',
          columns: ['E. Opening or closure of any business unit or location', 'None', 'CEO, CFO', 'Fund'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098fe2dcffe24': {
          id: '5ef26c9c6e9098fe2dcffe24',
          columns: ['F. Divest or dispose any business unit or location', 'None', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 6,
        totalQuestions: 6,
      },
      questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e909807cccffe25': {
      id: '5ef26c9c6e909807cccffe25',
      name: 'Finance - Lenders and Treasury',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e9098e66ccffe26': {
          id: '5ef26c9c6e9098e66ccffe26',
          columns: ['A. Issuance of letters of credit or commitment to any type of short or long-term indebtedness ', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90988a2bcffe27': {
          id: '5ef26c9c6e90988a2bcffe27',
          columns: ['B. Payment of non-revolving debt beyond mandatory payments', 'None', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90983108cffe28': {
          id: '5ef26c9c6e90983108cffe28',
          columns: ['C. Delivery of any financial, covenant, business or other information to members of the lender group\n\nNote all required monthly reports and covenant calculations must be reviewed by Fund prior to being sent to lenders.\n\nFund Deal Lead must be copied on all communication to the lenders', 'None', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098204ecffe29': {
          id: '5ef26c9c6e9098204ecffe29',
          columns: ['D. Enter into any external funding by or for any company and/or any non-wholly owned subsidiary', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909869f0cffe2a': {
          id: '5ef26c9c6e909869f0cffe2a',
          columns: ['E. Enter into any off-balance sheet lease finance', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90981d6fcffe2b': {
          id: '5ef26c9c6e90981d6fcffe2b',
          columns: ['F. Pledge of assets as security', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909834b0cffe2c': {
          id: '5ef26c9c6e909834b0cffe2c',
          columns: ['G. Receivable financing, factoring, discounting or securitization', 'None', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098228dcffe2d': {
          id: '5ef26c9c6e9098228dcffe2d',
          columns: ['H. Provide guarantees (other than ordinary course trading guarantees) and/or bonds or similar', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098ccd5cffe2e': {
          id: '5ef26c9c6e9098ccd5cffe2e',
          columns: ['I. Hedge any cash flows or interest rates or commodity prices', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90980d68cffe2f': {
          id: '5ef26c9c6e90980d68cffe2f',
          columns: ['J. Open and/or close any bank account or enter into any new bank relationship or hold any non-functional currency cash balances', 'None', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90986707cffe30': {
          id: '5ef26c9c6e90986707cffe30',
          columns: ['K. Bank interest or principal payments', 'Any amount', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 11,
        totalQuestions: 11,
      },
      questionStatistics: { COMPLIANT: 11, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 11, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 11, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 11, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e90986b6bcffe31': {
      id: '5ef26c9c6e90986b6bcffe31',
      name: 'Branding',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e90982e9bcffe32': {
          id: '5ef26c9c6e90982e9bcffe32',
          columns: ['A. Use of any trademark / brand (registered or unregistered), where not previously used', 'None', 'CEO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90983192cffe33': {
          id: '5ef26c9c6e90983192cffe33',
          columns: ['B. License in or out of any trademark', 'None', 'CEO', 'None'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098103ccffe34': {
          id: '5ef26c9c6e9098103ccffe34',
          columns: ['C. Register or lapse of registration of any trade mark ', 'None', 'CEO', 'None'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909809afcffe35': {
          id: '5ef26c9c6e909809afcffe35',
          columns: ['D. Create, change, release or dispose of any domain name', 'None', 'CEO', 'None'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098b647cffe36': {
          id: '5ef26c9c6e9098b647cffe36',
          columns: ['E. Create, change or issue any press release or public relations announcements including any material changes to the company website, publications, brochures or other media', 'None', 'CEO', 'Fund Approval Required for Press Releases Only'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 5,
        totalQuestions: 5,
      },
      questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e9098ddc4cffe37': {
      id: '5ef26c9c6e9098ddc4cffe37',
      name: 'Corporate Details',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e9098889ecffe38': {
          id: '5ef26c9c6e9098889ecffe38',
          columns: ['A. Companies or partnerships – change of registered address', 'None', 'CEO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909899b3cffe39': {
          id: '5ef26c9c6e909899b3cffe39',
          columns: ['B. Companies or partnerships – change of name', 'None', 'CEO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098131ccffe3a': {
          id: '5ef26c9c6e9098131ccffe3a',
          columns: ['C. Companies or partnerships – creation, merger or dissolution', 'None', 'CEO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90982b5ccffe3b': {
          id: '5ef26c9c6e90982b5ccffe3b',
          columns: ['D. Companies or partnerships – issue or cancel shares or capital', 'None', 'CEO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098ada1cffe3c': {
          id: '5ef26c9c6e9098ada1cffe3c',
          columns: ['E. Companies or partnerships – invest in or divest of shares or capital', 'None', 'CEO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90982070cffe3d': {
          id: '5ef26c9c6e90982070cffe3d',
          columns: ['F. Director or officer or equivalent – appoint or remove', 'None', 'CEO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098b012cffe3e': {
          id: '5ef26c9c6e9098b012cffe3e',
          columns: ['G. Registered branches – create or close', 'None', 'CEO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098eddccffe3f': {
          id: '5ef26c9c6e9098eddccffe3f',
          columns: ['H. Any grant of a power of attorney by either an entity and / or a director, officer or equivalent', 'None', 'CEO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 8,
        totalQuestions: 8,
      },
      questionStatistics: { COMPLIANT: 8, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 8, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 8, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 8, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e9098c46bcffe40': {
      id: '5ef26c9c6e9098c46bcffe40',
      name: 'Human Resources',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e90989c8dcffe41': {
          id: '5ef26c9c6e90989c8dcffe41',
          columns: ['A. Interview, appoint or dismiss any Tier 1 employee (direct report of the CEO), Tier 2 employee (direct report of T1 employee) and / or any employee with a basic salary of above:', '$115 thousand', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90980a9fcffe42': {
          id: '5ef26c9c6e90980a9fcffe42',
          columns: ['B. Effect any employee restructuring which impacts upon any Tier 1 employee (direct report of CEO), Tier 2 employee (direct report of T1 employee) or 15 or more employees (including temporary)', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098cf2ecffe43': {
          id: '5ef26c9c6e9098cf2ecffe43',
          columns: ['C. Recruitment of any finance or accounting positions', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90986694cffe44': {
          id: '5ef26c9c6e90986694cffe44',
          columns: ['D. Implement any severance plans', 'None', 'CEO, CFO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909811becffe45': {
          id: '5ef26c9c6e909811becffe45',
          columns: ['E. Appoint any employee to any newly created role or to any role which has not been filled within six months of it becoming vacant', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90985a3acffe46': {
          id: '5ef26c9c6e90985a3acffe46',
          columns: ['F. Establish, offer, change or remove any employee benefit or bonus scheme or other equivalent scheme, including sales commissions', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90986ee3cffe47': {
          id: '5ef26c9c6e90986ee3cffe47',
          columns: ['G. Establish, offer, change or remove any pension, post retirement benefit, lump sum payment or equivalent scheme', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909842efcffe48': {
          id: '5ef26c9c6e909842efcffe48',
          columns: ['H. Establish, offer, change or remove any life insurance, medical coverage, long term disability, death in service or equivalent scheme', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90980b85cffe49': {
          id: '5ef26c9c6e90980b85cffe49',
          columns: ['I. Establish, change or remove any arrangement with a trade union or other such employee representative body', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90987b6dcffe4a': {
          id: '5ef26c9c6e90987b6dcffe4a',
          columns: ['J. Any employee salary increase above agreed increase rate of >8%', 'None', 'CEO, CFO', 'Deal Lead if employee salary is over $115k before or after effect of increase'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 10,
        totalQuestions: 10,
      },
      questionStatistics: { COMPLIANT: 10, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 10, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 10, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 10, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5fdbc16f00ad7ea995728692': {
      id: '5fdbc16f00ad7ea995728692',
      name: 'Other',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5fdbc16f00ad7ecf60728693': {
          id: '5fdbc16f00ad7ecf60728693',
          columns: ['A. New Bid Discussion - Must be discussed on call', 'Greater than $70 thousand', 'CEO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5fdbc16f00ad7e139b728694': {
          id: '5fdbc16f00ad7e139b728694',
          columns: ['B. Final Contract Discussion - See above', 'Greater than $70 thousand', 'CEO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 2,
        totalQuestions: 2,
      },
      questionStatistics: { COMPLIANT: 2, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Jacob Allen',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 2, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 2, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 2, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e90988d5fcffe4b': {
      id: '5ef26c9c6e90988d5fcffe4b',
      name: 'Litigation and Insurance',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e9098666ecffe4c': {
          id: '5ef26c9c6e9098666ecffe4c',
          columns: ['A. Communication with any regulatory agency, regarding critical compliance matters or inquiries.', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90987414cffe4d': {
          id: '5ef26c9c6e90987414cffe4d',
          columns: ['B. Settlement of any disputes with vendors, customers, employees or regulatory agencies.  ', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909837f0cffe4e': {
          id: '5ef26c9c6e909837f0cffe4e',
          columns: ['C. Commence any litigation or other dispute or claim resolution proceedings (applies whether claims insured or not)', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909805eecffe4f': {
          id: '5ef26c9c6e909805eecffe4f',
          columns: ['D. Defend, admit liability and settle any dispute or claim (not the subject of any proceedings), litigation or other dispute or claim resolution proceedings (applies whether claims insured or not)', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909825facffe50': {
          id: '5ef26c9c6e909825facffe50',
          columns: ['E. Broker any insurance policies outside of the Company’s policies', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 5,
        totalQuestions: 5,
      },
      questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 5, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e909875adcffe51': {
      id: '5ef26c9c6e909875adcffe51',
      name: 'Property / Real Estate',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e9098a847cffe52': {
          id: '5ef26c9c6e9098a847cffe52',
          columns: ['A. Acquire or dispose of any interest', 'None', 'CEO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90985c8ccffe53': {
          id: '5ef26c9c6e90985c8ccffe53',
          columns: ['B. Agree to take any lease or sub-lease or to grant any lease or sub-lease', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098c944cffe54': {
          id: '5ef26c9c6e9098c944cffe54',
          columns: ['C. Assign/transfer any lease and/or license', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909835bacffe55': {
          id: '5ef26c9c6e909835bacffe55',
          columns: ['D. Charge, pledge or mortgage of any interest', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5fdbc16f00ad7e49107286a0': {
          id: '5fdbc16f00ad7e49107286a0',
          columns: ['E. Extend, renew and/or alter any lease or license (Non Job Related)', 'None', 'CEO, CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5fdbc16f00ad7e5ba87286a1': {
          id: '5fdbc16f00ad7e5ba87286a1',
          columns: ['F. Take any action in response to receipt of notice of any: request for information, inspection, audit, instruction to remediate or prosecution concerning any environmental issue, contamination or pollution', 'None', 'CEO, CFO', 'Fund Approval'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 6,
        totalQuestions: 6,
      },
      questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e9098d7f8cffe56': {
      id: '5ef26c9c6e9098d7f8cffe56',
      name: 'Tax',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e90985a26cffe57': {
          id: '5ef26c9c6e90985a26cffe57',
          columns: ['A. Take any action in response to receipt of notice of any tax audits by Revenue Agencies', 'None', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909808abcffe58': {
          id: '5ef26c9c6e909808abcffe58',
          columns: ['B. Take any action in response to receipt of notice of any exposure to financial penalties for non-compliance with taxes or late filings', 'None', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90983bcbcffe59': {
          id: '5ef26c9c6e90983bcbcffe59',
          columns: ['C. Any tax planning or non-ordinary course tax positions taken', 'None', 'CFO', 'Deal Lead'],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 3,
        totalQuestions: 3,
      },
      questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e90987c58cffe5a': {
      id: '5ef26c9c6e90987c58cffe5a',
      name: 'Risk Checklist - Overview',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e9098c54dcffe5b': {
          id: '5ef26c9c6e9098c54dcffe5b',
          columns: ['The following questions describe 14 anti-fraud risk controls across 4 categories: Cash Controls, Vendors, Employees, and Fraud-Specific. \n\nAnswering \'Compliant\' is equivalent to answering \'Attest,\' indicating that the control was in place at the end of the prior quarter. Answering \'Not Compliant\' is equivalent to answering \'Non-Attest,\' indicating that the control was not in place at the end of the prior quarter.\n\nPlease answer \'Compliant\' to this question, and move on to the next section to begin. ', ' ', '  ', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 1,
        totalQuestions: 1,
      },
      questionStatistics: { COMPLIANT: 1, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 1, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 1, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 1, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e9098f46fcffe5c': {
      id: '5ef26c9c6e9098f46fcffe5c',
      name: 'Risk Checklist - Cash Controls',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e90986440cffe5d': {
          id: '5ef26c9c6e90986440cffe5d',
          columns: ['1. Implement two-step \npayment approval process. \n\nThis is defined as: Require two people to be involved in all payments made from the business, which could take many forms, including:\n•\tTwo people independently approve each scheduled payment\n•\tOne person sets up payments and then a second person approves them\n•\tChecks require two signatures', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098fad3cffe5e': {
          id: '5ef26c9c6e9098fad3cffe5e',
          columns: ['2. Establish multi-factor authentication\n\nThis is defined as: To log-in to each bank account, require both an online password as well as another form of verification such as a text message code, an app-generated code, or phone call response', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90987c49cffe5f': {
          id: '5ef26c9c6e90987c49cffe5f',
          columns: ['3. Verbal confirmation of cash payments\n\nThis is defined as: In order to reduce the risk of phishing scams, all payment requests should be verbally confirmed to ensure legitimacy', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098539ccffe60': {
          id: '5ef26c9c6e9098539ccffe60',
          columns: ['4. Implement positive pay on bank accounts\n\nThis is defined as: All vendors should already have bank account and background information pre-populated in vendor lists and payments should only be allowed to such vendors, reducing the risk of false vendor data', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098aae8cffe61': {
          id: '5ef26c9c6e9098aae8cffe61',
          columns: ['5. Cross-staff and cross-train accounting team\n\nThis is defined as: Instead of segregating duties in accounting teams that enables individuals to control cash payments (even if you trust them!), ensure that team members share payment responsibilities to create mitigating redundancies as well reduce reliance on singular points of failure', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909814b4cffe62': {
          id: '5ef26c9c6e909814b4cffe62',
          columns: ['6. Audit any scheduled / recurring payments\n\nThis is defined as: Review and scrub recurring payments to ensure that vendors should still be receiving scheduled payments – do not rely on third parties to inform you that they are still receiving payments for services that are no longer being rendered', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 6,
        totalQuestions: 6,
      },
      questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 6, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e909852bccffe63': {
      id: '5ef26c9c6e909852bccffe63',
      name: 'Risk Checklist - Vendors',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e90985aafcffe64': {
          id: '5ef26c9c6e90985aafcffe64',
          columns: ['1. Audit current vendor list\n\nThis is defined as: Ensure that you recognize all vendors in vendor list and verify any vendors that are not familiar', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90985403cffe65': {
          id: '5ef26c9c6e90985403cffe65',
          columns: ['2. Remove old and duplicate \nvendors from vendor list\n\nThis is defined as: If vendors are entered into the vendor list more than once, remove duplicate accounts to avoid multiple payments being initiated; for vendors who have not been paid in more than three years, remove payment information from vendor list – these measures reduce probability for error', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098a91fcffe66': {
          id: '5ef26c9c6e9098a91fcffe66',
          columns: ['3. Set up dual approval process for new vendors and changes to existing vendor accounts\n\nThis is defined as: When adding vendors or changing account information, require two people to be involved in the process to ensure the integrity of vendors', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 3,
        totalQuestions: 3,
      },
      questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e909804cdcffe67': {
      id: '5ef26c9c6e909804cdcffe67',
      name: 'Risk Checklist - Employees',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e9098c177cffe68': {
          id: '5ef26c9c6e9098c177cffe68',
          columns: ['1. Audit payroll to validate as current\n\nThis is defined as: Ensure that payroll is current and that all employees who have left the company have been removed from payroll – it is possible that terminated employees who are still receiving paychecks would not report the occurrences', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e909843f9cffe69': {
          id: '5ef26c9c6e909843f9cffe69',
          columns: ['2. Implement dual approval process for new employees and all compensation changes\n\nThis is defined as: All employee additions and payroll changes should be reviewed and verified by two people to ensure the integrity of the additions and validate any raises', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e9098e753cffe6a': {
          id: '5ef26c9c6e9098e753cffe6a',
          columns: ['3. Require secondary review, approval, and attestation of all expense reports\n\nThis is defined as: All expense reports at all levels of the organization should be subjected to detailed review (on a transaction-level) and approval by a second person; all expense report policies should be updated and distributed to all employees', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 3,
        totalQuestions: 3,
      },
      questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'COMPLIANT',
          questionStatistics: { COMPLIANT: 3, NON_COMPLIANT: 0, NOT_APPLICABLE: 0 },
        },
      ],
    },
    '5ef26c9c6e909891c7cffe6b': {
      id: '5ef26c9c6e909891c7cffe6b',
      name: 'Risk Checklist - Fraud-Specific',
            type: 'AUTHORITY_MATRIX',
      columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
      questions: {
        '5ef26c9c6e909815c7cffe6c': {
          id: '5ef26c9c6e909815c7cffe6c',
          columns: ['1. Create log of recent and ongoing “near misses”\n\nThis is defined as: Anytime a weakness, threat, or gap is identified a report should be submitted that describes the problem, the failed control, preventing controls that stopped the risk, the root cause, mitigating steps that were taken, and the remaining risk outstanding – the near misses should be logged and tracked over time', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'COMPLIANT' as Types.AttestationOption,
            explanation: '',
            documents: {},
          },
        },
        '5ef26c9c6e90984c0dcffe6d': {
          id: '5ef26c9c6e90984c0dcffe6d',
          columns: ['2. Establish fraud reporting hotline for employees\n\nThis is defined as: Set up a 1-800 number for employees to anonymously report instances of fraud – this tool is the primary way that most fraud cases are identified', 'None', 'CEO, CFO', ' '],
          allowNA: false,
          allowDocuments: false,
          documentDescription: undefined,
          attestation: {
            attestation: 'NON_COMPLIANT' as Types.AttestationOption,
            explanation: 'Have not established a system.',
            documents: {},
          },
        },
      },
      progress: {
        numAnsweredQuestions: 2,
        totalQuestions: 2,
      },
      questionStatistics: { COMPLIANT: 1, NON_COMPLIANT: 1, NOT_APPLICABLE: 0 },
      users: [
        {
          id: 'auth0|5ef666493487d70013d47333',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
          picture: '',
          reportStatus: 'NON_COMPLIANT',
          questionStatistics: { COMPLIANT: 1, NON_COMPLIANT: 1, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef66660dd1f600019c9c29c',
          name: 'Jane Poe',
          email: 'jpoe@example.com',
          picture: '',
          reportStatus: 'NON_COMPLIANT',
          questionStatistics: { COMPLIANT: 1, NON_COMPLIANT: 1, NOT_APPLICABLE: 0 },
        },
        {
          id: 'auth0|5ef6666ea1c69c001979eed3',
          name: 'James Roe',
          email: 'jroe@example.com',
          picture: '',
          reportStatus: 'NON_COMPLIANT',
          questionStatistics: { COMPLIANT: 1, NON_COMPLIANT: 1, NOT_APPLICABLE: 0 },
        },
      ],
    },
  },
  users: {
    'auth0|5ef666493487d70013d47333': {
      id: 'auth0|5ef666493487d70013d47333',
      name: 'Charlotte Perez',
      email: 'jallen@complyify.com',
      picture: '',
      reportStatus: 'NON_COMPLIANT',
      completedOn: '2020-07-16T13:34:44.552Z',
      questionStatistics: { COMPLIANT: 92, NON_COMPLIANT: 1, NOT_APPLICABLE: 0 },
    },
    'auth0|5ef66660dd1f600019c9c29c': {
      id: 'auth0|5ef66660dd1f600019c9c29c',
      name: 'Wayne McKinney',
      email: 'wmckinney@complyify.com',
      picture: '',
      reportStatus: 'NON_COMPLIANT',
      completedOn: '2020-07-17T19:14:05.625Z',
      questionStatistics: { COMPLIANT: 92, NON_COMPLIANT: 1, NOT_APPLICABLE: 0 },
    },
    'auth0|5ef6666ea1c69c001979eed3': {
      id: 'auth0|5ef6666ea1c69c001979eed3',
      name: 'Cedric Knight',
      email: 'cknight@complyify.com',
      picture: '',
      reportStatus: 'NON_COMPLIANT',
      completedOn: '2020-07-02T19:57:46.244Z',
      questionStatistics: { COMPLIANT: 92, NON_COMPLIANT: 1, NOT_APPLICABLE: 0 },
    },
  },
  events: [
    {
      id: '5efb3758c274452f0e83a2c1',
      name: 'ReportCreated',
      timestamp: '2020-06-30T13:00:08.665Z',
      user: { id: 'system', name: 'System', email: 'help@complyify.com' },
    },
    {
      id: '5efdda570b494c00612f40df',
      name: 'DueSoonNoticeSent',
      timestamp: '2020-07-02T13:00:07.947Z',
      user: { id: 'system', name: 'System', email: 'help@complyify.com' },
    },
    {
      id: '5f0714d80f17cf0d5618bec5',
      name: 'DueSoonNoticeSent',
      timestamp: '2020-07-09T13:00:08.324Z',
      user: { id: 'system', name: 'System', email: 'help@complyify.com' },
    },
    {
      id: '5f0c5ad8d86f788f78ae440d',
      name: 'DueSoonNoticeSent',
      timestamp: '2020-07-13T13:00:08.182Z',
      user: { id: 'system', name: 'System', email: 'help@complyify.com' },
    },
    {
      id: '5f104f574f376f64288d630c',
      name: 'OverdueNoticeSent',
      timestamp: '2020-07-16T13:00:07.991Z',
      user: { id: 'system', name: 'System', email: 'help@complyify.com' },
    },
  ],
};

const mockReports: Types.Reports = {
  'f6143151-c61f-4164-9698-0a524dbd0c24': {
    ...mockReportDetail,
    id: 'f6143151-c61f-4164-9698-0a524dbd0c24',
    label: '2020 Q4',
    due: new Date(2021, 5, 15),
    status: 'NON_COMPLIANT',
    progress: {
      numAnsweredQuestions: 42,
      totalQuestions: 42,
    },
    signature: {
      name: 'Charlotte Perez',
      timestamp: new Date(2021, 1, 15),
      hash: '89e4a911-4b91-49dd-9cc6-108a54fb80bd',
    },
    started: new Date(2021, 1, 15),
  },
  '74ae8336-3c32-4362-89a3-4981c1b854af': {
    ...mockReportDetail,
    id: '74ae8336-3c32-4362-89a3-4981c1b854af',
    label: '2021 Q1',
    due: new Date(2021, 3, 1),
    status: 'PENDING',
    progress: {
      numAnsweredQuestions: 0,
      totalQuestions: 96,
    },
    signature: undefined,
    started: new Date(2021, 1, 15),
  },
};

export const temporaryMockReports: Types.Reports = {
  'f6143151-c61f-4164-9698-0a524dbd0c24': {
    id: 'f6143151-c61f-4164-9698-0a524dbd0c24',
    label: '2020 Q4',
    due: new Date(2021, 5, 15),
    status: 'COMPLIANT',
    progress: {
      numAnsweredQuestions: 42,
      totalQuestions: 42,
    },
    signature: {
      name: 'Charlotte Perez',
      timestamp: new Date(2021, 1, 15),
      hash: '89e4a911-4b91-49dd-9cc6-108a54fb80bd',
    },
    started: new Date(2021, 1, 15),
    events: [
      {
        name: 'EventName',
        timestamp: '20210115',
        user: {
          id: 'ea910aed-3c47-43bd-a99c-749736f75fa4',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
        },
      },
    ],
    instructions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rutrum lectus imperdiet mattis hendrerit.',
    sections: {
      'd7226f2c-a951-4c77-9048-e70516db94d7': {
        id: 'd7226f2c-a951-4c77-9048-e70516db94d7',
        name: 'Section Name Here',
        progress: {
          numAnsweredQuestions: 42,
          totalQuestions: 42,
        },
              type: 'AUTHORITY_MATRIX',
        columnNames: ['column name here'],
        questions: {
          '8921c0b1-9d96-420e-baff-6c1736b7ae5b': {
            id: '8921c0b1-9d96-420e-baff-6c1736b7ae5b',
            columns: ['Column Here'],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Doc Descrip Here',
            attestation: {
              explanation: 'Lorem Ipsum Explanation Here',
              attestation: 'COMPLIANT' as Types.AttestationOption,
              documents: {
                'c2cd0f7e-88ac-44aa-9b52-bebf29a913e2': {
                  id: 'c2cd0f7e-88ac-44aa-9b52-bebf29a913e2',
                  name: 'Doc Name Here',
                  type: 'Doc Type Here',
                },
              },
            },
          },
        },
      },
    },
  },
  '74ae8336-3c32-4362-89a3-4981c1b854af': {
    id: '74ae8336-3c32-4362-89a3-4981c1b854af',
    label: '2021 Q1',
    due: new Date(2021, 3, 1),
    status: 'PENDING',
    progress: {
      numAnsweredQuestions: 42,
      totalQuestions: 42,
    },
    signature: undefined,
    started: new Date(2021, 1, 15),
    events: [
      {
        name: 'EventName',
        timestamp: '20210115',
        user: {
          id: '8c25f82d-90fa-4824-bd33-b393fc40d324',
          name: 'Charlotte Perez',
          email: 'jdoe@example.com',
        },
      },
    ],
    instructions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rutrum lectus imperdiet mattis hendrerit.',
    sections: {
      '6643921b-ab31-4dc6-886d-8a27d692d557': {
        id: '6643921b-ab31-4dc6-886d-8a27d692d557',
        name: 'Section Name Here',
        progress: {
          numAnsweredQuestions: 42,
          totalQuestions: 42,
        },
              type: 'AUTHORITY_MATRIX',
        columnNames: ['column name here'],
        questions: {
          '179575d5-14d1-42f2-a4d4-d72d31c1828b': {
            id: '179575d5-14d1-42f2-a4d4-d72d31c1828b',
            columns: ['Column Here'],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Doc Descrip Here',
            attestation: {
              explanation: 'Lorem Ipsum Explanation Here',
              attestation: 'COMPLIANT' as Types.AttestationOption,
              documents: {
                '6934c1e7-1e39-42ac-97e6-fffbde4db2b8': {
                  id: '6934c1e7-1e39-42ac-97e6-fffbde4db2b8',
                  name: 'Doc Name Here',
                  type: 'Doc Type Here',
                },
              },
            },
          },
        },
      },
    },
  },
};

export default mockReports;
