import {
  React, styled, Icons, useTranslation,
} from '@riskforge/platform-web';
import {
  map,
  isNil,
  isString,
  isNumber,
  isEmpty,
  omit,
  parseInt,
  toUpper,
  trim,
  round,
  toArray,
  forEach,
  includes,
  isArray,
  isObject,
  concat,
  filter,
} from 'lodash';
import {
  AttestationOption, KeyedQuestion, KeyedAttestation, Document,
} from '@riskforge/jawa-core/dist/types';
import { colors } from '@riskforge/jawa-theme';
import Explanation from './explanation';

export type TempAttestationType = KeyedAttestation | string | number | Record<string, number | undefined> | 'NOT_APPLICABLE' | 'CONFLICTING_ANSWERS' | undefined;

interface CustomAnswerProps {
  question: KeyedQuestion;
  sectionId: string;
  readonly?: boolean;
  validationError?: string;
  uploadDocument: (sectionId: string, questionId: string, documents: File) => void;
  removeDocument: (sectionId: string, questionId: string, documentId: string) => void;
  handleUpdate: (
    sectionId: string,
    question: KeyedQuestion,
    attestation?: AttestationOption,
    explanation?: string,
    comment?: string,
  ) => void;
}

interface BreakdownAnswerSchema {
  answer?: number;
  readonly?: boolean;
  handleUpdate: (answer?: number) => void;
}

const CustomAnswerWrapper = styled.div`

`;
const PercentIcon = styled.div`
  font-size: 1.5rem;
  color: #ccc;
  padding-left: 0.5rem;
  font-weight: 900;
`;
const AnswerContainer = styled.form`
  margin: 1rem 0;
  display: flex;
  align-items: center;
`;
// const AnswerLabel = styled.div`
//   font-size: 0.8rem;
//   font-weight: 700;
//   margin: 0 0 0.5rem;
//   color: #aaa;
// `;
const AnswerNumberInput = styled.input`
  padding: 0.5rem;
  border: 1px solid ${colors.mainText};
  border-radius: 0.5rem;
  width: 100%;
  resize: none;
  overflow: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  &:disabled {
    background: transparent;
  }
`;
const AnswerTextInput = styled.textarea`
  padding: 0.5rem;
  border: 1px solid ${colors.mainText};
  border-radius: 0.5rem;
  width: 100%;
  resize: none;
  overflow: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  &:disabled {
    background: transparent;
  }
`;
const AnswerBreakdownWrapper = styled.form`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0;
`;
const AnswerCategoryLabel = styled.div`
  color: #555;
  font-weight: 400;
  font-size: 1.1rem;
  justify-self: end;
  text-align: right;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
`;
const AnswerCategoryValue = styled.div`
  color: #555;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 0.25rem 2rem 0.25rem 0;
  justify-self: stretch;
  text-align: left;
`;
const AnswerMultiselectWrapper = styled.form`

`;
const CheckboxOption = styled.div`
  display: grid;
  grid-template-columns: 1rem auto;
  grid-gap: 0.5rem;
  padding: 0.25rem 0;
`;
const CheckboxInput = styled.input`
  cursor: pointer;
  user-select: none;
  &:disabled {
    cursor: auto;
  }
`;
const CheckboxLabel = styled.label<{ isReadOnly?: boolean }>`
  cursor: ${(p): string => (p.isReadOnly ? 'auto' : 'pointer')};
  user-select: none;
  text-align: left;
`;
const AnswerMultipleChoiceWrapper = styled.form`

`;
const RadioOption = styled.div`
  display: grid;
  grid-template-columns: 1rem auto;
  grid-gap: 0.5rem;
  padding: 0.25rem 0;
`;
const RadioLabel = styled.label<{ isReadOnly?: boolean }>`
  display: block;
  text-align: left;
  cursor: ${(p): string => (p.isReadOnly ? 'auto' : 'pointer')};
  user-select: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
`;
const RadioInput = styled.input`
  display: block;
  cursor: pointer;
  user-select: none;
  &:disabled {
    cursor: auto;
  }
`;
const ValidationErrorMessage = styled.div`
  color: rgb(240, 67, 62);
  text-align: center;
  font-size: 0.8rem;
`;
const CommentWrapper = styled.div`
  grid-column: 1 / -1;
`;
const OtherAnswerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1rem auto;
  grid-gap: 0.5rem;
  margin: 0;
`;
const OtherAnswerCheckbox = styled.input`
  cursor: pointer;
  user-select: none;
  &:disabled {
    cursor: auto;
  }
`;
const OtherAnswerLabel = styled.label<{ isReadOnly?: boolean }>`
  cursor: ${(p): string => (p.isReadOnly ? 'auto' : 'pointer')};
  user-select: none;
  text-align: left;
`;
const OtherOptionInputWrapper = styled.div`
  grid-column: 1 / -1;
`;
const OtherOptionInput = styled.textarea<{ isOptional: boolean }>`
  padding: 0.5rem;
  border: 1px solid ${(p): string => (p.isOptional ? '#555' : colors.error)};
  border-radius: 0.5rem;
  width: 100%;
  resize: none;
  overflow: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
`;
const NotApplicableWrapper = styled.form`
  display: grid;
  grid-template-columns: 1rem auto;
  grid-gap: 0.5rem;
  margin: 1rem 0;
`;
const NotApplicableCheckbox = styled.input`
  cursor: pointer;
  user-select: none;
  &:disabled {
    cursor: auto;
  }
`;
const NotApplicableLabel = styled.label<{ isReadOnly?: boolean }>`
  cursor: ${(p): string => (p.isReadOnly ? 'auto' : 'pointer')};
  user-select: none;
  text-align: left;
`;
const ExplanationWrapper = styled.div`
  grid-column: 1 / -1;
`;
const DocumentsContainer = styled.div`
  margin: 1rem 0;
`;
const DocumentsList = styled.div`
  margin: 0 0 1rem;
`;
const DocumentItem = styled.div`
  display: grid;
  grid-template-columns: 1rem auto 1rem;
  grid-gap: 0.5rem;
  align-items: center;
`;
const DocumentName = styled.div`
  text-align: left;
  font-size: 0.8rem;
  font-weight: 700;
`;
const DocumentRemoveButton = styled.div`
  cursor: pointer;
  user-select: none;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
`;
const DocumentsLabel = styled.div`
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0 0 1rem;
`;
const AddFileInput = styled.label`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem auto;
  font-size: 0.8rem;

  padding: 0.5rem 0;
  width: 8rem;
  border-radius: 0.3em;
  text-align: center;
  font-weight: 700;
  background: ${colors.activeBg};
  color: ${colors.active};

  &:hover { opacity: 0.85; }
  &:active { box-shadow: none; }

  & > svg {
    font-size: 1.5em;
    line-height: 0;
  }

  input[type=file] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    top: 0;
    left: 0;
    height: 100px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 100%;
  }
`;


const handleSubmit = (event: any): void => {
  event.preventDefault();
};

const BreakdownNumberAnswer: React.FunctionComponent<BreakdownAnswerSchema> = ({ answer, readonly, handleUpdate }) => {
  const [value, setValue] = React.useState<string | number | undefined>(answer);

  const handleChange = React.useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  return (
    <AnswerNumberInput
      type="number"
      value={value as string}
      disabled={readonly}
      onChange={handleChange}
      onBlur={(event_: any): void => handleUpdate(event_.target.value)}
    />
  );
};

const CustomAnswer: React.FunctionComponent<CustomAnswerProps> = ({
  question,
  sectionId,
  readonly,
  validationError,
  handleUpdate,
  removeDocument,
  uploadDocument,
}) => {
  // console.log('question', {
  //   question,
  //   sectionId,
  //   readonly,
  //   validationError,
  // });
  const { t } = useTranslation();
  // const initBreakdownValue = question.attestation?.attestation;
  const [value, setValue] = React.useState<AttestationOption | undefined>(question.attestation?.attestation);
  const [isNotApplicableChecked, setIsNotApplicableChecked] = React.useState<boolean>(question.attestation?.attestation === 'NOT_APPLICABLE');
  const [isOtherAnswerChecked, setIsOtherAnswerChecked] = React.useState<boolean>(false);
  const [localComment, setLocalComment] = React.useState<string | undefined>(undefined);
  const [otherValue, setOtherValue] = React.useState<string | undefined>(undefined);

  // const [multiselectSelections, setMultiselectSelections] = React.useState<string[] | undefined>(question.attestation?.attestation?.selections);
  // const [explanation, setExplanation] = React.useState<string>('');
  // const [breakdownAnswer, setBreakdownAnswer] = React.useState<Record<string, string>>(question.attestation?.attestation || {});

  // React.useEffect(() => {
  //   setBreakdownAnswer(question.attestation?.attestation)
  // }, [question]);

  const handleUpload = (event: any, questionId: string): void => {
    event.preventDefault();
    const files = toArray(event.target.files);
    forEach(files, (document: File) => uploadDocument(sectionId, questionId, document));
  };

  const isNotApplicable = isNotApplicableChecked || value === 'NOT_APPLICABLE';

  const handleChange = React.useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const handleNumberBlurChange = React.useCallback(
    (answer) => {
      setValue(answer);
    },
    [setValue],
  );

  // const handleOtherOptionChange = React.useCallback(
  //   (event) => {
  //     setValue(event.target.value);
  //   },
  //   [setValue],
  // );

  const userAnswer: AttestationOption | undefined = question.attestation?.attestation;

  const multiselectSelections = (): string[] => (!isNil(userAnswer)
      && !isString(userAnswer)
      && !isNumber(userAnswer)
      && 'selections' in userAnswer
      && isObject(userAnswer)
      && isArray(userAnswer.selections)
      && !isNumber(userAnswer.selections)
    ? filter(userAnswer.selections)
    : []);

  const otherOption = (): string | undefined => (!isNil(userAnswer)
      && !isString(userAnswer)
      && !isNumber(userAnswer)
      && 'otherOption' in userAnswer
      && isObject(userAnswer)
      && isString(userAnswer.otherOption)
    ? userAnswer.otherOption
    : undefined);

  const handleOtherOptionUpdate = (event: any): void => {
    const newAttestation: { selections: string[]; otherOption?: string } = {
      selections: multiselectSelections(),
      otherOption: event.target.value,
    };
    setValue(newAttestation);
    handleUpdate(sectionId, question, newAttestation, undefined, localComment);
  };

  const handleOtherCheckboxChange = (): void => {
    if (isOtherAnswerChecked) {
      const newAttestation: { selections: string[]; otherOption?: string | null } = {
        selections: multiselectSelections(),
        otherOption: null,
      };
      setValue(newAttestation);
      handleUpdate(sectionId, question, newAttestation, undefined, localComment);
    }
    setIsOtherAnswerChecked(!isOtherAnswerChecked);
  };

  const handleNAChange = (): void => {
    if (!readonly) {
      if (isNotApplicable) {
        setValue(undefined);
        setIsNotApplicableChecked(false);
        handleUpdate(sectionId, question, undefined);
      } else {
        setValue(undefined);
        setIsNotApplicableChecked(true);
        handleUpdate(sectionId, question, 'NOT_APPLICABLE');
      }
    }
  };

  const handleCheckboxChange = (event: any): void => {
    if (!readonly) {
      console.log('handleCheckboxChange()', event.target.value);
      let selections = multiselectSelections();
      if (includes(selections, event.target.value)) {
        selections = filter(selections, (s) => s !== event.target.value);
      } else {
        selections = concat(selections, event.target.value);
      }
      const newAttestation: { selections: string[]; otherOption?: string } = {
        selections: filter(selections),
        otherOption: otherOption(),
      };
      setValue(newAttestation);
      handleUpdate(sectionId, question, newAttestation, undefined, localComment);
    }
  };

  const handleRadioChange = (event: any): void => {
    if (!readonly) {
      handleChange(event);
      handleUpdate(sectionId, question, event.target.value, undefined, localComment);
    }
  };

  const handleBreakdownChange = (answer: string | number | undefined, label: string): void => {
    if (!readonly) {
      const ans = isString(answer) ? parseInt(answer) : answer;
      const existing: Record<string, number | undefined> = isString(userAnswer) || isNumber(userAnswer) || isNil(userAnswer) || 'selections' in userAnswer ? {} : userAnswer;
      const breakdownAnswerObject: Record<string, number> = isNil(ans) ? omit(existing, label) : {
        ...existing,
        [label]: ans,
      };
      handleUpdate(sectionId, question, breakdownAnswerObject, undefined, localComment);
    }
  };

  const handleExplanationChange = (
    sId: string,
    qId: string,
    attestation: AttestationOption,
    explanation: string,
  ): void => {
    if (!readonly && !isEmpty(explanation)) {
      handleUpdate(sectionId, question, attestation, explanation);
    }
  };

  const handleCommentChange = (
    sId: string,
    qId: string,
    attestation: AttestationOption | undefined,
    comment: string,
  ): void => {
    if (!readonly) {
      setLocalComment(comment)
      handleUpdate(sectionId, question, attestation, undefined, comment);
    }
  };

  const renderNotApplicable = (): React.ReactElement | undefined => {
    if (question.allowNA) {
      return (
        <NotApplicableWrapper onSubmit={handleSubmit}>
          <NotApplicableCheckbox
            type="checkbox"
            id={`${question.id}-notApplicable`}
            name={`${question.id}-notApplicable`}
            onChange={handleNAChange}
            checked={isNotApplicable}
            disabled={readonly}
          />
          <NotApplicableLabel
            htmlFor={`${question.id}-notApplicable`}
            isReadOnly={readonly}
          >
            Not Applicable
          </NotApplicableLabel>
          {isNotApplicableChecked && (
            <ExplanationWrapper>
              <Explanation
                initValue={question.attestation.explanation || ''}
                handleUpdate={handleExplanationChange}
                section={sectionId}
                question={question.id}
                attestation="NOT_APPLICABLE"
              />
            </ExplanationWrapper>
          )}
        </NotApplicableWrapper>
      );
    }
    return undefined;
  };

  if (!question.type) return <div />;

  let answerComponent: React.ReactElement = <div />;

  if (['INTEGER', 'PERCENTAGE'].includes(question.type) && (isNil(userAnswer) || isString(userAnswer) || isNumber(userAnswer))) {
    answerComponent = (
      <AnswerContainer onSubmit={handleSubmit}>
        <AnswerNumberInput
          type="number"
          value={value as string}
          disabled={readonly}
          onChange={handleChange}
          onBlur={(event_: any): void => {
            const answer = isString(event_.target.value)
              ? round(Number.parseFloat(event_.target.value), 2)
              : event_.target.value;
            handleNumberBlurChange(answer);
            return handleUpdate(sectionId, question, answer, undefined, localComment);
          }}
        />
        {question.type === 'PERCENTAGE' && <PercentIcon>%</PercentIcon>}
      </AnswerContainer>
    );
  }

  if (question.type === 'SHORT_ANSWER' && (isNil(userAnswer) || isString(userAnswer)) && !isNotApplicable) {
    answerComponent = (
      <AnswerContainer onSubmit={handleSubmit}>
        <AnswerTextInput
          rows={6}
          value={value as string}
          disabled={readonly}
          onChange={handleChange}
          onBlur={(event_: any): void => {
            const updatedAnswer = trim(event_.target.value);
            if (!isEmpty(updatedAnswer)) {
              handleUpdate(sectionId, question, updatedAnswer, undefined, localComment);
            }
          }}
        />
      </AnswerContainer>
    );
  }

  if (question.type === 'BREAKDOWN') {
    answerComponent = (
      <AnswerBreakdownWrapper onSubmit={handleSubmit}>
        {!isNil(question.options)
          ? map(question.options, (option) => (
            <React.Fragment key={`option-${option}-${question.id}`}>
              <AnswerCategoryLabel>
                {`${option}:`}
              </AnswerCategoryLabel>
              <AnswerCategoryValue>
                <BreakdownNumberAnswer
                  answer={
                    !isNil(userAnswer) && !isString(userAnswer) && !isNumber(userAnswer) && !('selections' in userAnswer) && !isNotApplicableChecked
                      ? userAnswer[option]
                      : undefined
                  }
                  readonly={readonly}
                  handleUpdate={(answer: string | number | undefined): void => handleBreakdownChange(answer, option)}
                />
              </AnswerCategoryValue>
            </React.Fragment>
          )) : <div />}
      </AnswerBreakdownWrapper>
    );
  }

  if (question.type === 'MULTI_SELECT') {
    answerComponent = (
      <AnswerMultiselectWrapper onSubmit={handleSubmit}>
        {map(question.options, (opt) => {
          const isChecked = includes(multiselectSelections(), opt);
          return (
            <CheckboxOption key={`option-${opt}-${question.id}`}>
              <CheckboxInput
                type="checkbox"
                id={`${question.id}-${opt}`}
                name={`${question.id}-${opt}`}
                value={opt}
                onChange={handleCheckboxChange}
                checked={isChecked}
                disabled={readonly}
              />
              <CheckboxLabel
                htmlFor={`${question.id}-${opt}`}
                isReadOnly={readonly}
              >
                {opt}
              </CheckboxLabel>
            </CheckboxOption>
          );
        })}
        {question.allowOther && (
          <OtherAnswerWrapper>
            <OtherAnswerCheckbox
              type="checkbox"
              id={`${question.id}-isOther`}
              name={`${question.id}-isOther`}
              onChange={handleOtherCheckboxChange}
              checked={!!(otherOption()) || isOtherAnswerChecked}
              disabled={readonly}
            />
            <OtherAnswerLabel
              htmlFor={`${question.id}-isOther`}
              isReadOnly={readonly}
            >
              Other
            </OtherAnswerLabel>
            {!!(otherOption()) || isOtherAnswerChecked ? (
              <OtherOptionInputWrapper>
                <OtherOptionInput
                  rows={1}
                  value={otherValue || otherOption()}
                  onChange={(e) => setOtherValue(e.target.value)}
                  onBlur={handleOtherOptionUpdate}
                  isOptional={!isOtherAnswerChecked || !isEmpty(otherOption())}
                />
              </OtherOptionInputWrapper>
            ) : <div />}
          </OtherAnswerWrapper>
        )}
      </AnswerMultiselectWrapper>
    );
  }

  if (question.type === 'MULTIPLE_CHOICE') {
    answerComponent = (
      <AnswerMultipleChoiceWrapper onSubmit={handleSubmit}>
        {map(question.options, (opt) => {
          const isSelected = !isNotApplicable && isString(userAnswer) && toUpper(trim(opt)) === toUpper(trim(userAnswer));
          return (
            <RadioOption key={`option-${opt}-${question.id}`}>
              <RadioInput
                type="radio"
                id={`${question.id}-${opt}`}
                name={question.id}
                value={opt}
                onChange={handleRadioChange}
                checked={isSelected}
                disabled={readonly}
              />
              <RadioLabel
                htmlFor={`${question.id}-${opt}`}
                isReadOnly={readonly}
              >
                {opt}
              </RadioLabel>
            </RadioOption>
          );
        })}
      </AnswerMultipleChoiceWrapper>
    );
  }

  if (question.type === 'FILE') {
    answerComponent = (
      <DocumentsContainer>
        <DocumentsLabel>Uploaded Documents</DocumentsLabel>
        <DocumentsList>
          {map(question.attestation?.documents, (document: Document) => (
            <DocumentItem key={document.id}>
              <Icons.fa.FaFileAlt />
              <DocumentName>{document.name}</DocumentName>

              {!readonly && (
                <DocumentRemoveButton
                  onClick={(): void => removeDocument(sectionId, question.id, document.id)}
                >
                  <Icons.fa.FaTrashAlt color={colors.error} />
                </DocumentRemoveButton>
              )}
            </DocumentItem>
          ))}
        </DocumentsList>
        {!readonly && (
          <AddFileInput htmlFor={`addFile-${sectionId}-${question.id}`}>
            <Icons.md.MdFileUpload />
            { t('uploadFile') }
            <input
              type="file"
              id={`addFile-${sectionId}-${question.id}`}
              onChange={(event: any): void => handleUpload(event, question.id)}
            />
          </AddFileInput>
        )}
      </DocumentsContainer>
    );
  }

  return (
    <CustomAnswerWrapper key={`custom-company-answer-${question.id}`}>
      {!isNotApplicable && answerComponent}
      {renderNotApplicable()}
      {question.allowComment && !isNotApplicable && (
        <CommentWrapper>
          <Explanation
            initValue={question.attestation.comment || ''}
            handleUpdate={handleCommentChange}
            section={sectionId}
            question={question.id}
            attestation={userAnswer}
            isComment
          />
        </CommentWrapper>
      )}
      {validationError && !isNotApplicable && (
        <ValidationErrorMessage>{validationError}</ValidationErrorMessage>
      )}
    </CustomAnswerWrapper>
  );
};

export default CustomAnswer;
