import {
  React, styled, Icons,
} from '@riskforge/platform-web';
import { colors } from '@riskforge/jawa-theme';

import Modal from 'components/modal';

const HelpModalContainer = styled.div`
  width: 30rem;
  padding: 2rem;
  & > * {
    margin: 0 0 1rem;
  }
`;

const CloseIcon = styled.div`
  font-size: 1.5rem;
  color: ${colors.inactive};
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${colors.active};
  }
`;
const ModalTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`;


interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose }: HelpModalProps) => {


  const ModalContent = (
    <>
      <ModalTitle>
        Have questions? Need assistance?
        <CloseIcon
          onClick={(): void => {
            onClose();
          }}
        >
          <Icons.fa.FaTimes />
        </CloseIcon>
      </ModalTitle>

      <HelpModalContainer>
        Send us an email at support@complyify.com to connect with our support team. A dedicated team member will respond
        within 1 (one) business day.
      </HelpModalContainer>
    </>
  );
  // }

  return <Modal isOpen={isOpen} content={ModalContent} onClose={onClose} width="lg" />;
};

export default HelpModal;
