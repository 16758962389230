import { colors } from '@riskforge/jawa-theme';
import {
  React,
  styled,
} from '@riskforge/platform-web';
import Demo3LogoImg from './dxyq.png';

interface Demo3LogoProps {
  color?: string;
}

interface FormStyleProps {
  logoColor?: string;
}

const LogoWrapper = styled.div<FormStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 6rem;
`;
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Demo3Logo: React.FC<Demo3LogoProps> = ({ color }: Demo3LogoProps) => {
  const logoColor = color || colors.text;
  return (
    <LogoWrapper logoColor={logoColor}>
      <StyledLogo src={Demo3LogoImg} alt="DXYQ Capital" />
    </LogoWrapper>
  );
};

export default Demo3Logo;
