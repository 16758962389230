import { layout } from '@riskforge/jawa-theme';
import { styled } from '@riskforge/platform-web';

const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${layout.maxContentInnerWidth};
  min-height: 9rem;
  * {
    text-align: center;
    text-align-last: center;
    margin-bottom: 1rem;
  }
  p {
    max-width: 75%;
    font-size: 1.2rem;
    @media (max-width: 1000px) {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
  @media (max-width: 1000px) {
    min-height: 8rem;
  }
  @media (max-width: 720px) {
    display: none;
  }
  @media (max-height: 420px) {
      display: none;
  }
`;

export default AccountInfo;
