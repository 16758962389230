import {
  filter, size, split,
} from 'lodash';
import { State } from '@riskforge/jawa-app-state';
import jwtDecode from 'jwt-decode';
import {
  Div100vh,
  GlobalContext,
  React,
  ViewProperties,
  useDispatch,
  useSelector,
  useTranslation,
  useAuth0,
} from '@riskforge/platform-web';
import { useAuth0 as useMockAuth0 } from 'utils/mock-auth0';
import {
  AccountInfo,
  ErrorComponent,
  ReportList,
  ReportListTitle,
  StyledContainer,
} from 'components';
import { GlobalContext as JawaContext } from 'contexts';
import { isMockData } from 'utils/brand-config';
import mockReports from 'mockData/mock-reports';

const ReportListView: React.FunctionComponent<ViewProperties> = () => {
  const { t } = useTranslation();
  const { actionCreator } = React.useContext(GlobalContext) as JawaContext;
  const [reportListError, setReportListError] = React.useState<Error | undefined>(undefined);
  const dispatch = useDispatch();

  const auth0Context = isMockData ? useMockAuth0 : useAuth0;
  const {
    getTokenSilently, user, logout,
  } = auth0Context();

  React.useEffect(() => {
    (async (): Promise<void> => {
      const token = await getTokenSilently();
      if (token) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const decodedToken: any = jwtDecode(token);
        const tokenScope = split(decodedToken.scope, ' ');
        const isAdmin = tokenScope.includes('client:create');
        if (isAdmin) {
          sessionStorage.clear();
          logout();
        }
      }
    })();
  }, [getTokenSilently, logout]);

  React.useEffect(() => {
    if (user && !isMockData) {
      (async (): Promise<void> => {
        const token = await getTokenSilently();
        actionCreator
          .listReports(dispatch, token, user['https://app_metadata.com/'].companyId as string)
          .catch(setReportListError);
      })();
    }
  }, [getTokenSilently, actionCreator, user, dispatch]);

  const reports = useSelector((state: State) => state.reports);

  const reportsList = isMockData ? mockReports : reports;

  const pendingCount = size(filter(reportsList, (r) => !r?.signature));
  // const isAnyReportComplete = !isEmpty(filter(reportsList, (r) => !!r?.signature));

  if (!user) return <div />;
  const validUser = {
    ...user,
    id: user.sub,
    fullName: user.name,
    email: user.email,
  };

  const noPending = (
    <AccountInfo>
      <h1>{t('noPendingReportsMessage')}</h1>
      <p>{t('noPendingReportsNextStep')}</p>
    </AccountInfo>
  );
  const singlePending = (
    <AccountInfo>
      <h1>{t('singlePendingReportsMessage')}</h1>
      <p>{t('singlePendingReportsNextStep')}</p>
    </AccountInfo>
  );
  const multiplePending = (
    <AccountInfo>
      <h1>{t('multiplePendingReportsMessage')}</h1>
      <p>{t('multiplePendingReportsNextStep')}</p>
    </AccountInfo>
  );

  return (
    <Div100vh>
      <StyledContainer user={validUser}>
        { reportListError && !isMockData ? <ErrorComponent error={reportListError} /> : undefined}
        {pendingCount === 0 ? noPending : (pendingCount === 1 ? singlePending : multiplePending)}
        <ReportListTitle hasSecondColumn>{t('yourReports')}</ReportListTitle>
        <ReportList reports={reportsList} />
      </StyledContainer>
    </Div100vh>
  );
};


export default ReportListView;
