import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from '@riskforge/jawa-app-state';
import { App, ServiceWorker } from '@riskforge/platform-web';
import { isMockData } from 'utils/brand-config';
import { MockAuth0Provider } from 'utils/mock-auth0';
import Loader from 'components/loader';

import MockApp from './mock-app';

import { GlobalContext } from './contexts';
import * as Views from './views';

import './global.css';

import authConfigDemo1 from './auth_config_demo1.json';
import authConfigDemo2 from './auth_config_demo2.json';
import authConfigDemo3 from './auth_config_demo3.json';
import authConfigDemo4 from './auth_config_demo4.json';
import authConfigDemo5 from './auth_config_demo5.json';
import authConfigTrive from './auth_config_trive.json';
import authConfigKainos from './auth_config_kainos.json';
import authConfigNewstate from './auth_config_newstate.json';
import authConfigTailwater from './auth_config_tailwater.json';
import authConfigTrinityHunt from './auth_config_trinityhunt.json';
import authConfigHig from './auth_config_hig.json';
import authConfigStonePoint from './auth_config_stonepoint.json';
import authConfigAbry from './auth_config_abry.json';
import authConfigAltamont from './auth_config_altamont.json';
import authConfigKelso from './auth_config_kelso.json';
import authConfigAcon from './auth_config_acon.json';

const localhostAuth = authConfigTrive;

const authConfig = window.location.host.startsWith('reportify-demo1')
  ? authConfigDemo1
  : window.location.host.startsWith('reportify-demo2')
    ? authConfigDemo2
    : window.location.host.startsWith('reportify-demo3')
      ? authConfigDemo3
      : window.location.host.startsWith('reportify-demo4')
        ? authConfigDemo4
        : window.location.host.startsWith('reportify-demo5')
          ? authConfigDemo5
          : window.location.host.startsWith('kainos')
            ? authConfigKainos
            : window.location.host.startsWith('newstate')
              ? authConfigNewstate
              : window.location.host.startsWith('tailwater')
                ? authConfigTailwater
                : window.location.host.startsWith('trinityhunt')
                  ? authConfigTrinityHunt
                  : window.location.host.startsWith('hig')
                    ? authConfigHig
                    : window.location.host.startsWith('stonepoint')
                      ? authConfigStonePoint
                      : window.location.host.startsWith('abry')
                        ? authConfigAbry
                        : window.location.host.startsWith('altamont')
                          ? authConfigAltamont
                          : window.location.host.startsWith('kelso')
                            ? authConfigKelso
                            : window.location.host.startsWith('acon')
                              ? authConfigAcon
                              : (window.location.host.startsWith('localhost')
                                ? localhostAuth
                                : authConfigTrive);

const routes = [
  {
    path: '/',
    exact: true,
    default: true,
    component: Views.ReportList,
    authenticated: true,
  },
  {
    path: '/report/:reportId',
    component: Views.Report,
    authenticated: true,
  },
];

if (isMockData) {
  const render = () => {
    ReactDOM.render(
      <MockAuth0Provider>
        <Provider store={store}>
          <Suspense fallback={<Loader />}>
            <MockApp />
          </Suspense>
        </Provider>
      </MockAuth0Provider>,
      document.querySelector('#root'),
    );
  };

  render();
} else {
  App.render(routes, store, GlobalContext, authConfig, {});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ServiceWorker.unregister();
