export interface IdToken {
  __raw: string;
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  iss?: string;
  aud?: string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  azp?: string;
  nonce?: string;
  auth_time?: string;
  at_hash?: string;
  c_hash?: string;
  acr?: string;
  amr?: string;
  sub_jwk?: string;
  cnf?: string;
  sid?: string;
  sub?: string;
}

export interface Auth0User extends Omit<IdToken, '__raw'> {
  'https://app_metadata.com/': {
    clientId?: string;
    companyId?: string;
  };
}

export const mockAuth0User: Auth0User = {
  name: 'Charlotte Perez',
  email: 'cperez@betterplace.com',
  email_verified: true,
  'https://app_metadata.com/': {
    clientId: 'b95cc8aa-4ccb-42f1-97ee-bd88b0d8703f',
    companyId: '6659748d-f381-4b9d-92e5-ec7a4770e9f7',
  },
  nickname: 'cperez',
  picture: '',
  updated_at: '2021-03-01T19:59:36.405Z',
  sub: '1ab4bdef-fad8-4a4c-a486-99cb33d113d2',
};
