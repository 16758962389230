import { colors } from '@riskforge/jawa-theme';
import {
  React,
  styled,
} from '@riskforge/platform-web';
import AconLogoImg from './acon.png';

interface AconLogoProps {
  color?: string;
}

interface FormStyleProps {
  logoColor?: string;
}

const LogoWrapper = styled.div<FormStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 4rem;
  width: 15rem;
  padding: 0.5rem;
`;
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const AconLogo: React.FC<AconLogoProps> = ({ color }: AconLogoProps) => {
  const logoColor = color || colors.text;
  return (
    <LogoWrapper logoColor={logoColor}>
      <StyledLogo src={AconLogoImg} alt="Acon Investments" />
    </LogoWrapper>
  );
};

export default AconLogo;
