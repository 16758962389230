import { colors } from '@riskforge/jawa-theme';
import {
  React,
  styled,
} from '@riskforge/platform-web';
import TrinityHuntLogoImg from './trinityhunt.png';

interface TrinityHuntLogoProps {
  color?: string;
}

interface FormStyleProps {
  logoColor?: string;
}

const LogoWrapper = styled.div<FormStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 4rem;
  width: 15rem;
`;
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  filter: brightness(0) invert(1);
`;

const TrinityHuntLogo: React.FC<TrinityHuntLogoProps> = ({ color }: TrinityHuntLogoProps) => {
  const logoColor = color || colors.text;
  return (
    <LogoWrapper logoColor={logoColor}>
      <StyledLogo src={TrinityHuntLogoImg} alt="Trinity Hunt Partners" />
    </LogoWrapper>
  );
};

export default TrinityHuntLogo;
