import { ActionCreator } from '@riskforge/jawa-app-state';

interface JawaContext {
  token: string;
  actionCreator: ActionCreator;
}

const browserHref = window.location.href;

const JawaContext = {
  actionCreator: browserHref.includes('reportify-demo1')
    ? new ActionCreator('reportify-demo1-portco.comply.cloud', 443, 'https', '/demo1')
    : browserHref.includes('reportify-demo2')
      ? new ActionCreator('reportify-demo2-portco.comply.cloud', 443, 'https', '/demo2')
      : browserHref.includes('reportify-demo3')
        ? new ActionCreator('reportify-demo3-portco.comply.cloud', 443, 'https', '/demo3')
        : browserHref.includes('reportify-demo4')
          ? new ActionCreator('reportify-demo4-portco.comply.cloud', 443, 'https', '/demo4')
          : browserHref.includes('reportify-demo5')
            ? new ActionCreator('reportify-demo5-portco.comply.cloud', 443, 'https', '/demo5')
            : browserHref.includes('trive')
              ? new ActionCreator('trive.comply.cloud', 443, 'https', '/trive')
              : browserHref.includes('kainos')
                ? new ActionCreator('kainos-portco.comply.cloud', 443, 'https')
                : browserHref.includes('newstate')
                  ? new ActionCreator('newstate-portco.comply.cloud', 443, 'https', '/newstate')
                  : browserHref.includes('tailwater')
                    ? new ActionCreator('tailwater-portco.comply.cloud', 443, 'https', '/tailwater')
                    : browserHref.includes('hig')
                      ? new ActionCreator('hig-portco.comply.cloud', 443, 'https', '/trinityhunt')
                      : browserHref.includes('stonepoint')
                        ? new ActionCreator('stonepoint-portco.comply.cloud', 443, 'https', '/stonepoint')
                        : browserHref.includes('abry')
                          ? new ActionCreator('abry-portco.comply.cloud', 443, 'https', '/abry')
                          : browserHref.includes('altamont')
                            ? new ActionCreator('altamont-portco.comply.cloud', 443, 'https', '/altamont')
                            : browserHref.includes('kelso')
                              ? new ActionCreator('kelso-portco.comply.cloud', 443, 'https', '/kelso')
                              : (browserHref.includes('acon')
                                ? new ActionCreator('acon-portco.comply.cloud', 443, 'https', '/acon')
                                : new ActionCreator('localhost', 3003, 'http')),
};

export default JawaContext;
