import {
  map, omit, size, toArray, forEach, isEmpty, isNil, isString, isNumber, isInteger, filter,
} from 'lodash';
import { Types } from '@riskforge/jawa-core';
import {
  React, styled, useTranslation, Icons, Link,
} from '@riskforge/platform-web';
import { colors } from '@riskforge/jawa-theme';
import { AttestationOption } from '@riskforge/jawa-core/dist/types';
import { ErrorComponent, Explanation, CustomAnswer } from 'components';
import Markdown from 'markdown-to-jsx';

interface SectionEditProps {
  className?: string;
  section?: Types.KeyedSection;
  instructions?: string | React.ReactElement<any>;
  readonly?: boolean;
  reportError?: Error;
  uploadDocument?: (sectionId: string, questionId: string, documents: File) => void;
  removeDocument?: (sectionId: string, questionId: string, documentId: string) => void;
  createAttestation?: (sectionId: string, questionId: string, attestation?: Types.AttestationOption, explanation?: string, comment?: string) => void;
}

const MainArea = styled.div`
  position: relative;
  height: auto;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
`;
const ContentWrapper = styled.div`
  margin: 0 0 5rem;
`;
const Button = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 1rem;
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: 1rem auto;
  background: ${(p): string => (p.isActive ? colors.activeBg : colors.inactiveBg)};
  color: ${(p): string => (p.isActive ? colors.active : colors.inactive)};
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 12rem;
  & > * {
    justify-self: center;
  }
  &:hover {
    opacity: 0.8;
  }
`;
const TitleBanner = styled.div`
  background: ${colors.bannerBg};
  color: ${colors.mainText};
  position: fixed;
  top: 4rem;
  width: calc(100% - 18rem);
  height: 4rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 1rem 2rem;
  & > * {
    margin-left: 1rem;
  }
`;
const CloseButton = styled(Link)`
  cursor: pointer;
  user-select: none;
`;
const SectionContainer = styled.div`
  width: calc(100% - (2 * 2rem));
  max-width: 80rem;
  margin: 4rem auto 0;
  padding: 2rem;
  color: ${colors.mainText};
  overflow-y: auto;
`;
const InstructionsContainer = styled(SectionContainer)`
  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
  a:hover {
    background: rgba(83, 117, 198, 0.05);
  }
`;
const SectionTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 1rem;
`;
const ContentBox = styled.div`
  background: ${colors.contentBoxBg};
  box-shadow: 0 0 0.5rem 0 ${colors.shadow};
  border-radius: 0.5rem;
`;
const SectionTableHeader = styled.div<{ columnLength: number; isCustom: boolean }>`
  display: grid;
  grid-template-columns: ${(p): string => (p.isCustom ? 'auto 15rem' : `2fr repeat(${p.columnLength - 1}, 1fr) 15rem`)};
  grid-gap: 0.5rem;
  align-items: center;
  border-bottom: 1px solid ${colors.shadow};
  padding: 1rem;
`;
const SectionHeaderItem = styled.div`
  font-weight: 700;
  font-size: 0.8rem;
  text-align: center;
  &:first-child {
    text-align: left;
  }
`;
const SectionQuestionRow = styled.div<{ columnLength: number; isCustom: boolean }>`
  display: grid;
  grid-template-columns: ${(p): string => (p.isCustom ? 'auto 15rem' : `2fr repeat(${p.columnLength - 1}, 1fr) 15rem`)};
  grid-gap: 0.5rem;
  align-items: start;
  border-bottom: 1px solid ${colors.shadow};
  padding: 1rem;
  &:last-child {
    border-bottom: none;
  }
`;
const SectionQuestionItem = styled.div`
  text-align: center;
  &:first-child {
    text-align: left;
  }
  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
  a:hover {
    background: rgba(83, 117, 198, 0.05);
  }
`;
const DocumentsContainer = styled.div`
  margin: 1rem 0;
`;
const DocumentDescription = styled.div`
  font-weight: 700;
`;
const DocumentError = styled.div`
  font-size: 0.8rem;
  color: ${colors.warn};
  font-weight: 700;
  text-align: center;
  margin: 0.5rem 0;
`;
const DocumentsList = styled.div`
  margin: 0 0 1rem;
`;
const DocumentItem = styled.div`
  display: grid;
  grid-template-columns: 1rem auto 1rem;
  grid-gap: 0.5rem;
  align-items: center;
`;
const DocumentName = styled.div`
  text-align: left;
  font-size: 0.8rem;
  font-weight: 700;
`;
const DocumentRemoveButton = styled.div`
  cursor: pointer;
  user-select: none;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
`;

const AttestationWrapper = styled.div <{ gridSize: number }>`
  display: grid;
  grid-template-columns: repeat(${(p): number => p.gridSize}, 1fr);
  border: 1px solid ${colors.shadow};
  border-radius: 0.5rem;
  align-items: stretch;
`;
const AttestationButton = styled.div <{ isSelected: boolean; isInactive: boolean; attestation?: AttestationOption }>`
  color: ${(p): string => (p.isSelected ? colors.text : (p.isInactive ? colors.shadow : colors.mainText))};
  background: ${(p): string => {
    if (p.isSelected && p.attestation === 'COMPLIANT') return colors.COMPLIANT;
    if (p.isSelected && p.attestation === 'NON_COMPLIANT') return colors.NON_COMPLIANT;
    if (p.isSelected && p.attestation === 'NOT_APPLICABLE') return colors.NOT_APPLICABLE;
    return colors.contentBoxBg;
  }};
  font-weight: ${(p): string => (p.isSelected ? '700' : '400')};

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0.5rem 0;
  border-left: 1px solid ${colors.shadow};
  cursor: pointer;
  user-select: none;
  &:first-child {
    border-left: none
  }
`;
export const AddFileInput = styled.label`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem auto;
  font-size: 0.8rem;

  padding: 0.5rem 0;
  width: 8rem;
  border-radius: 0.3em;
  text-align: center;
  font-weight: 700;
  background: ${colors.activeBg};
  color: ${colors.active};

  &:hover { opacity: 0.85; }
  &:active { box-shadow: none; }

  & > svg {
    font-size: 1.5em;
    line-height: 0;
  }

  input[type=file] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    top: 0;
    left: 0;
    height: 100px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 100%;
  }
`;
const CloseIcon = styled.div`
  cursor: pointer;
  user-select: none;
`;
const InstructionsTitleBar = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InstructionsTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 1rem;
`;

const SectionEdit: React.FunctionComponent<SectionEditProps> = ({
  readonly = false,
  section,
  instructions,
  reportError,
  uploadDocument = (): void => { },
  removeDocument = (): void => { },
  createAttestation = (): void => { },
}) => {
  const { t } = useTranslation();
  // const [pendingAttestation, setPendingAttestation] = React.useState<{ [id: string]: Types.AttestationOption }>({});
  const [isInstructionsOpen, setIsInstructionsOpen] = React.useState<boolean>(false);
  const [customError, setCustomError] = React.useState<Record<string, string>>({});

  const handleUpload = (event: any, sectionId: string, questionId: string): void => {
    event.preventDefault();
    const files = toArray(event.target.files);
    forEach(files, (document: File) => uploadDocument(sectionId, questionId, document));
  };

  if (!section) return <div />;

  const validateAttestation = (
    sectionId: string,
    question: Types.KeyedQuestion,
    attestation?: AttestationOption,
  ): void => {
    let temporaryError: string | undefined;

    if (isNil(attestation) && question.type !== 'FILE') {
      temporaryError = 'Required Answer';
    }

    if (question.type === 'INTEGER' || question.type === 'PERCENTAGE') {
      const MIN = question.minValue || 0;

      // there is always a MIN, it is either provided by `minValue` or it is `0`
      if (!isNumber(attestation) || isNil(attestation) || attestation < MIN) {
        temporaryError = `A number of ${MIN} or greater is required`;
      }

      // if a `maxValue` is given show error messages that indicate the MAX
      if (isNumber(question.maxValue)) {
        if (!isNumber(attestation) || isNil(attestation) || attestation < MIN || attestation > question.maxValue) {
          temporaryError = `A number between ${MIN} and ${question.maxValue} is required`;
        }
      }
    }

    if (question.type === 'BREAKDOWN' && !isString(attestation) && !isNumber(attestation) && (!isNil(attestation) && !('selections' in attestation))) {
      const invalidCategoryAnswers: boolean[] = filter(map(question.options, (opt) => {
        console.log('valid', attestation, opt, !isNil(attestation), !isNil(attestation) && !isNil(attestation[opt]), !isNil(attestation) && !isNil(attestation[opt]) && !isInteger(attestation[opt]));
        if (!isNil(attestation) && !isNil(attestation[opt])) {
          return !isInteger(attestation[opt]);
        }
        return false;
      }));
      if (!isEmpty(invalidCategoryAnswers)) {
        temporaryError = 'A whole number of 0 or greater is required for each category';
      }
    }

    if (attestation === 'NOT_APPLICABLE') {
      temporaryError = undefined;
    }

    // if (!temporaryError) {
    //   setCustomError(omit(customError, `${sectionId}_${question.id}`));
    //   return true;
    // }
    // setCustomError({
    //   ...customError,
    //   [`${sectionId}_${question.id}`]: temporaryError,
    // });
    // return false;

    // console.log('validateAttestation()', {
    //   attestation,
    //   temporaryError,
    // });

    if (!isNil(temporaryError)) {
      return setCustomError({
        ...customError,
        [`${sectionId}_${question.id}`]: temporaryError,
      });
    }
    return setCustomError(omit(customError, `${sectionId}_${question.id}`));
  };

  const handleAttestationChange = (
    sectionId: string,
    question: Types.KeyedQuestion,
    attestation?: AttestationOption,
    explanation?: string,
    comment?: string,
  ): void => {
    if (!readonly) {
      validateAttestation(sectionId, question, attestation);

      const existingComment = isNil(question.attestation?.comment) ? undefined : question.attestation?.comment;
      const commentForPayload = isNil(comment) ? existingComment : comment;
      // console.log('createAttestation()', {
      //   sectionId,
      //   question,
      //   attestation,
      //   explanation,
      //   comment,
      //   existingComment,
      //   commentForPayload,
      // });
      createAttestation(sectionId, question.id, attestation, explanation, commentForPayload);
    }
  };

  const handleExplanationChange = (
    sectionId: string,
    questionId: string,
    attestation: AttestationOption,
    explanation: string,
  ): void => {
    if (!readonly && !isEmpty(explanation)) {
      createAttestation(sectionId, questionId, attestation, explanation);
    }
  };

  const renderExplanation = (
    selectedSection: Types.KeyedSection,
    question: Types.KeyedQuestion,
    selectedAttestation?: AttestationOption,
  ): React.ReactElement<any> | null => {
    if (selectedAttestation && selectedSection.type !== 'CUSTOM') {
      return (
        <Explanation
          initValue={question.attestation.explanation || ''}
          handleUpdate={handleExplanationChange}
          section={selectedSection.id}
          question={question.id}
          attestation={selectedAttestation}
        />
      );
    }
    return <div />;
  };

  const isCustom = section.type === 'CUSTOM';

  const content = isInstructionsOpen ? (
    <InstructionsContainer>
      <InstructionsTitleBar>
        <InstructionsTitle>
          {t('instructions')}
        </InstructionsTitle>
        <CloseIcon onClick={(): void => setIsInstructionsOpen(false)}>
          <Icons.fa.FaTimes size="1.6rem" color={colors.mainText} />
        </CloseIcon>
      </InstructionsTitleBar>
      <Markdown>
        { instructions as string }
      </Markdown>
    </InstructionsContainer>
  ) : (
    <SectionContainer>
      {reportError && <ErrorComponent error={reportError} />}
      <SectionTitle>{section.name}</SectionTitle>

      <ContentBox>
        <SectionTableHeader columnLength={size(section.columnNames)} isCustom={isCustom}>
          {isCustom ? (
            <SectionHeaderItem>Question</SectionHeaderItem>
          ) : (
            map(section.columnNames, (columnName: string) => (
              <SectionHeaderItem key={columnName}>{columnName}</SectionHeaderItem>
            ))
          )}
          <SectionHeaderItem>{isCustom ? 'Answer' : t('attestationHeaderLabel')}</SectionHeaderItem>
        </SectionTableHeader>

        {map(section.questions, (question: Types.KeyedQuestion) => (
          <SectionQuestionRow key={question.id} columnLength={size(question.columns)} isCustom={isCustom}>
            {isCustom ? (
              <SectionQuestionItem key={`quest-${question.id}`}>
                <Markdown>
                  {question.query as string}
                </Markdown>
              </SectionQuestionItem>
            ) : (
              map(question.columns, (column: string) => (
                <SectionQuestionItem key={`quest-${question.id}-${column}`}>
                  {column}
                </SectionQuestionItem>
              ))
            )}

            <SectionQuestionItem>
              {isCustom ? (
                <CustomAnswer
                  question={question}
                  readonly={readonly}
                  sectionId={section.id}
                  validationError={customError[`${section.id}_${question.id}`]}
                  handleUpdate={handleAttestationChange}
                  uploadDocument={uploadDocument}
                  removeDocument={removeDocument}
                />

              ) : (
                <AttestationWrapper gridSize={question.allowNA ? 3 : 2}>
                  <AttestationButton
                    onClick={(): void => handleAttestationChange(section.id, question, 'COMPLIANT')}
                    attestation={question.attestation.attestation}
                    isSelected={question.attestation.attestation === 'COMPLIANT'}
                    isInactive={!isNil(question.attestation.attestation) && question.attestation.attestation !== 'COMPLIANT'}
                  >
                    {t('compliant')}
                  </AttestationButton>
                  <AttestationButton
                    onClick={(): void => handleAttestationChange(section.id, question, 'NON_COMPLIANT')}
                    attestation={question.attestation.attestation}
                    isSelected={question.attestation.attestation === 'NON_COMPLIANT'}
                    isInactive={!isNil(question.attestation.attestation) && question.attestation.attestation !== 'NON_COMPLIANT'}
                  >
                    {t('non_compliant')}
                  </AttestationButton>
                  {question.allowNA && (
                  <AttestationButton
                    onClick={(): void => handleAttestationChange(section.id, question, 'NOT_APPLICABLE')}
                    attestation={question.attestation.attestation}
                    isSelected={question.attestation.attestation === 'NOT_APPLICABLE'}
                    isInactive={!isNil(question.attestation.attestation) && question.attestation.attestation !== 'NOT_APPLICABLE'}
                  >
                    {t('not_applicable')}
                  </AttestationButton>
                  )}
                </AttestationWrapper>
              )}

              {renderExplanation(section, question, question.attestation.attestation)}

              {question.allowDocuments && (
              <DocumentsContainer>
                <DocumentDescription>{question.documentDescription}</DocumentDescription>
                {question.attestation.attestation === 'COMPLIANT' && isEmpty(question.attestation?.documents) && (
                <DocumentError>{t('docRequiredError')}</DocumentError>
                )}
                <DocumentsList>
                  {map(question.attestation?.documents, (document: Types.Document) => (
                    <DocumentItem key={document.id}>
                      <Icons.fa.FaFileAlt />
                      <DocumentName>{document.name}</DocumentName>

                      {!readonly && (
                      <DocumentRemoveButton
                        onClick={(): void => removeDocument(section.id, question.id, document.id)}
                      >
                        <Icons.fa.FaTrashAlt color={colors.error} />
                      </DocumentRemoveButton>
                      )}
                    </DocumentItem>
                  ))}
                </DocumentsList>
                {!readonly && (
                <AddFileInput htmlFor={`addFile-${section.id}-${question.id}`}>
                  <Icons.md.MdFileUpload />
                  { t('uploadFile') }
                  <input
                    type="file"
                    id={`addFile-${section.id}-${question.id}`}
                    onChange={(event: any): void => handleUpload(event, section.id, question.id)}
                  />
                </AddFileInput>
                )}
              </DocumentsContainer>
              )}
            </SectionQuestionItem>
          </SectionQuestionRow>
        ))}
      </ContentBox>
    </SectionContainer>
  );

  return (
    <MainArea>
      <TitleBanner>
        <CloseButton to="/">
          <Icons.fa.FaTimes color={colors.inactive} size="2rem" />
        </CloseButton>
        <Button isActive className="triggerChat">
          <Icons.fa.FaComment />
          {t('contactSupport')}
        </Button>
        {!!instructions && (
          <Button onClick={(): void => setIsInstructionsOpen(!isInstructionsOpen)}>
            <Icons.fa.FaListOl />
            {t('viewInstructions')}
          </Button>
        )}
      </TitleBanner>
      <ContentWrapper>
        {content}
      </ContentWrapper>
    </MainArea>
  );
};

export default SectionEdit;
