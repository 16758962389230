import { colors } from '@riskforge/jawa-theme';
import {
  React,
  styled,
} from '@riskforge/platform-web';
import TailwaterLogoImg from './tailwater.png';

interface TailwaterLogoProps {
  color?: string;
}

interface FormStyleProps {
  logoColor?: string;
}

const LogoWrapper = styled.div<FormStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 4rem;
  width: 15rem;
`;
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  filter: brightness(0) invert(1);
`;

const TailwaterLogo: React.FC<TailwaterLogoProps> = ({ color }: TailwaterLogoProps) => {
  const logoColor = color || colors.text;
  return (
    <LogoWrapper logoColor={logoColor}>
      <StyledLogo src={TailwaterLogoImg} alt="Tailwater Capital" />
    </LogoWrapper>
  );
};

export default TailwaterLogo;
