import { State } from '@riskforge/jawa-app-state';
import { Types } from '@riskforge/jawa-core';
import {
  isEmpty, isNil, split,
} from 'lodash';
import {
  GlobalContext,
  React,
  Redirect,
  ViewProperties,
  useDispatch,
  useParams,
  useSelector,
  useAuth0,
  useTranslation,
  Icons,
  styled,
} from '@riskforge/platform-web';
import { useAuth0 as useMockAuth0 } from 'utils/mock-auth0';
import { colors, layout } from '@riskforge/jawa-theme';
import {
  ReportEdit,
  ReportEntry,
  StyledContainer,
  ReportSign,
  Button,
  Signature,
} from 'components';
import { GlobalContext as JawaContext } from 'contexts';
import { isMockData, brandConfig } from 'utils/brand-config';
import mockReports from 'mockData/mock-reports';
import jwtDecode from 'jwt-decode';

const BottomBar = styled.div`
  height: 4rem;
  background: ${colors.background};
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
   h3 {
     font-size: 1.6rem;
   }
`;
const StyledButton = styled(Button)`
  background-color: ${colors.active};
  border-radius: ${layout.widgetBorderRadius};
  height: 3rem;
  margin: 0.5rem 0;
`;
const ContainerWrapper = styled.div`
  position: relative;
  height: 100vh;
`;

const ReportView: React.FunctionComponent<ViewProperties> = (): React.ReactElement => {
  const { reportId } = useParams();
  const { t } = useTranslation();
  const { actionCreator } = React.useContext(GlobalContext) as JawaContext;
  const reports = useSelector((state: State) => state.reports);
  const dispatch = useDispatch();
  const reportsList = isMockData ? mockReports : reports;

  const [reportError, setReportError] = React.useState<Error | undefined>(undefined);
  const [isSignatureHidden, setIsSignatureHidden] = React.useState<boolean>(true);
  const [reportIsSigned, setIsReportSigned] = React.useState<boolean>(false);

  const auth0Context = isMockData ? useMockAuth0 : useAuth0;
  const { getTokenSilently, user, logout } = auth0Context();

  React.useEffect(() => {
    (async (): Promise<void> => {
      const token = await getTokenSilently();
      if (token) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const decodedToken: any = jwtDecode(token);
        const tokenScope = split(decodedToken.scope, ' ');
        const isAdmin = tokenScope.includes('client:create');
        if (isAdmin) {
          sessionStorage.clear();
          logout();
        }
      }
    })();
  }, [getTokenSilently, logout]);


  React.useEffect(() => {
    if (!isNil(user) && !isNil(user['https://app_metadata.com/']) && isNil(user['https://app_metadata.com/'].companyId)) {
      sessionStorage.clear();
      logout();
    }
  }, [user, logout]);


  React.useEffect(() => {
    if (reportId && !isMockData) {
      (async (): Promise<void> => {
        const token = await getTokenSilently();
        actionCreator.findReport(dispatch, token, reportId).catch(setReportError);
        // setIsReportSigned(reportsList[reportId])
      })();
    }
  }, [getTokenSilently, reportId, dispatch, actionCreator]);

  const startReport = async (): Promise<void> => {
    if (reportId && !isMockData) {
      const token = await getTokenSilently();
      actionCreator
        .startReport(dispatch, token, reportId)
        .catch(setReportError);
    }
  };

  const removeDocument = async (sectionId: string, questionId: string, documentId: string): Promise<void> => {
    if (reportId) {
      const token = await getTokenSilently() as string;
      actionCreator
        .removeDocument(dispatch, token, reportId, {
          sectionId,
          questionId,
          documentId,
        })
        .then(() => actionCreator.findReport(dispatch, token, reportId))
        .catch(setReportError);
    }
  };

  const uploadDocument = async (sectionId: string, questionId: string, document: File): Promise<void> => {
    if (reportId) {
      const token = await getTokenSilently() as string;
      actionCreator
        .uploadDocument(dispatch, token, reportId, document, {
          sectionId,
          questionId,
          name: document.name,
          type: document.type,
        })
        .then(() => actionCreator.findReport(dispatch, token, reportId))
        .catch(setReportError);
    }
  };

  const createAttestation = async (sectionId: string, questionId: string, attestation?: Types.AttestationOption, explanation?: string, comment?: string): Promise<void> => {
    if (reportId) {
      const token = await getTokenSilently() as string;
      actionCreator
        .createAttestation(dispatch, token, reportId, {
          sectionId,
          questionId,
          explanation,
          attestation,
          comment,
        })
        // .then(() => actionCreator.findReport(dispatch, token, reportId))
        .catch((error) => {
          console.log('createAttestation ERROR', error);
          setReportError(error);
        });
    }
  };

  const signReport = async (signatureName: string): Promise<void> => {
    if (reportId) {
      const token = await getTokenSilently() as string;
      actionCreator
        .signReport(dispatch, token, reportId, signatureName).catch(setReportError);
    }
  };

  const handleReportSign = (formInputs: any): void => {
    if (formInputs.signatureCheck && !isEmpty(formInputs.signatureName)) {
      if (!isMockData) signReport(formInputs.signatureName);
      setIsReportSigned(true);
    }
  };

  if (!user) return <div />;
  const validUser = {
    ...user,
    id: user.sub,
    fullName: user.name,
    email: user.email,
  };

  if (!reportId || reportIsSigned) return <Redirect to="/" />;
  if (isEmpty(reportsList) || isEmpty(reportsList[reportId]?.sections)) return <div />;

  const report = reportsList[reportId];
  const reportEdit = (): React.ReactNode => (report.started ? (
    <ReportEdit
      report={report}
      uploadDocument={uploadDocument}
      removeDocument={removeDocument}
      createAttestation={createAttestation}
      reportError={isMockData ? undefined : reportError}
    />
  ) : (
    <ReportEntry user={validUser} report={report} onSubmit={startReport} />
  ));

  const reportView = (): React.ReactNode => (
    report.signature ? <ReportEdit report={report} reportError={isMockData ? undefined : reportError} readonly /> : reportEdit()
  );

  const isReadyToSign = report && report.progress
      && report.progress.totalQuestions === report.progress.numAnsweredQuestions;

  const isFullWidthTitlebar = !isEmpty(report.signature) || (!!report.started && isSignatureHidden);

  const sig = report.signature as Types.Signature;

  return (
    <ContainerWrapper>
      <StyledContainer user={validUser} fullWidth={isFullWidthTitlebar}>
        { isSignatureHidden && reportView() }
        { isReadyToSign && isEmpty(report.signature) && (
          <>
            {isSignatureHidden && (
              <BottomBar>
                <StyledButton
                  label={t('signSubmit')}
                  icon={Icons.fa.FaPencilAlt}
                  handler={(): void => setIsSignatureHidden(false)}
                />
              </BottomBar>
            )}

            <ReportSign
              isHidden={isSignatureHidden}
              reportName={report.label}
              clientName={brandConfig?.brandName}
              handler={handleReportSign}
              handleCancel={(): void => setIsSignatureHidden(true)}
            />
          </>
        )}

        {!isEmpty(sig) && (
          <BottomBar>
            <h3>{t('readOnlySignedReport')}</h3>
            <Signature signature={sig} />
            <div>
              <p>{t('digitallySigned')}</p>
              <p>{t('formattedTimestamp', { timestamp: sig?.timestamp })}</p>
            </div>
          </BottomBar>
        )}
      </StyledContainer>
    </ContainerWrapper>
  );
};


export default ReportView;
