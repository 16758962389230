import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { isUndefined } from 'lodash';
import * as Views from './views';

const App: React.FC = () => {
  const user: any = {};

  sessionStorage.setItem('user', JSON.stringify(user));

  if (!isUndefined(user)) sessionStorage.setItem('app_metadata', JSON.stringify(user['https://app_metadata.com/']));

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Views.ReportList} />
        <Route path="/report/:reportId?" component={Views.Report} />
      </Switch>
    </Router>
  );
};

export default App;
