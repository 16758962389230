import { colors } from '@riskforge/jawa-theme';
import {
  React,
  styled,
} from '@riskforge/platform-web';
import AltamontLogoImg from './altamont.png';

interface AltamontLogoProps {
  color?: string;
}

interface FormStyleProps {
  logoColor?: string;
}

const LogoWrapper = styled.div<FormStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 4rem;
  width: 15rem;
`;
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const AltamontLogo: React.FC<AltamontLogoProps> = ({ color }: AltamontLogoProps) => {
  const logoColor = color || colors.text;
  return (
    <LogoWrapper logoColor={logoColor}>
      <StyledLogo src={AltamontLogoImg} alt="Altamont Capital Partners" />
    </LogoWrapper>
  );
};

export default AltamontLogo;
