import { styled, React } from '@riskforge/platform-web';

interface ReportTitleProps {
  children: React.ReactNode;
  hasSecondColumn?: boolean;
}

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-height: 2rem;
`;
const SecondColumnHeading = styled.div`
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  @media (max-width: 720px) {
    display: none;
  }
`;
const TitleStyle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  @media (max-width: 720px) {
    display: none;
  }
`;
const TitleStyleFullWidth = styled(TitleStyle)`
  width: 95%;
  min-height: 2rem;
`;

const ReportTitle: React.FC<ReportTitleProps> = ({ children, hasSecondColumn }) => {
  if (hasSecondColumn) {
    return (
      <TitleRow>
        <TitleStyle>{ children }</TitleStyle>
        <SecondColumnHeading>
          Export PDF
        </SecondColumnHeading>
      </TitleRow>
    );
  }
  return (
    <TitleStyleFullWidth>{ children }</TitleStyleFullWidth>
  );
};

export default ReportTitle;
