import { React, styled, useTranslation } from '@riskforge/platform-web';
import { colors } from '@riskforge/jawa-theme';

const ErrorContainer = styled.div`
  color: ${colors.error};
  margin: 0 auto 0.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
`;

interface ErrorComponentProps {
  error?: Error;
  serverResponse?: string;
  hideContactMsg?: boolean;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({
  error,
  serverResponse,
  hideContactMsg: hideContactMessage = true,
}) => {
  const { t } = useTranslation();

  return (
    <ErrorContainer>
      <div>
        {error ? t(error.message) : (serverResponse ? t(serverResponse) : '')}
        <br />
        <br />
        {hideContactMessage ? '' : t('contactSupportMsg')}
      </div>
    </ErrorContainer>
  );
};

export default ErrorComponent;
